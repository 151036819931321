import { works } from "@/api/work/useWorkQuery";
import useFetcher from "@/hooks/useFetcher";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

interface useNonStandardFetchProps {
  workData: ProductionPlansGet200ResponseRowsInnerWorksInner;
}

export const useNonStandardFetch = ({ workData }: useNonStandardFetchProps) => {
  const createdAt = workData?.createdAt;
  const startOfDay = dayjs(createdAt).startOf("day").toISOString(); // 00:00:00
  const endOfDay = dayjs(createdAt).endOf("day").toISOString(); // 23:59:59

  const query: any = {
    $and: [
      {
        createdAt: {
          $gte: startOfDay,
          $lt: endOfDay,
        },
      },
    ],
  };

  if (workData?.equipmentName) {
    query.$and.push({ equipmentName: { $eq: workData.equipmentName } });
  }

  // 업체명
  if (workData?.CustName) {
    query.$and.push({ CustName: { $eq: workData.CustName } });
  }

  // 현장명
  if (workData?.DVPlaceName) {
    query.$and.push({ DVPlaceName: { $eq: workData.DVPlaceName } });
  }

  const { queryKey, queryFn } = works.itemWork({
    query: query,
    pageSize: 10,
    sort: "id",
    populate: [
      "equipment",
      "summary",
      "item",
      "productionPlan",
      "routingData",
      "lot",
    ],
  });

  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    { enabled: !!createdAt }
  );

  return { data, Fetcher };
};
