import { BaseButton } from "@/components/atom/button/BaseButton";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { FormTitle } from "@/components/atom/title/form/FormTitle";
import { MantineCheckedTable } from "@/components/molecule/mantineCheckedTable/MantineCheckedTable";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useEquipmentFormFetch } from "@/fetch/form/useEquipmentFormFetch";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { useEnrollDailyWorkerHandler } from "@/hooks/handler/useEnrollDailyWorkerHandler";

import dayjs from "dayjs";
import { useState } from "react";

const AssignWorker = Object.assign(
  {},
  {
    Template: FormTemplate,
    Title: FormTitle,
    SubTitle: FormSubTitle,
    Button: BaseButton,
    Table: MantineCheckedTable,
  }
);

export const AssignWorkerForm = () => {
  const { closeModal } = useModal();
  const [codes, setCodes] = useState<string[]>([]);
  const today = dayjs(new Date()).format("YYYY-MM-DD");

  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const { equipmentName, equipmentCode } = userData ?? {};
  const columns = [{ accessorKey: "name", header: "작업자명" }];
  const { data: equipmentData, Fetcher } = useEquipmentFormFetch({
    code: equipmentCode,
  });
  const { onSubmit } = useEnrollDailyWorkerHandler();

  const data = equipmentData?.data[0].workerGroupArray;

  const onRowSelection = (rows: any[]) => {
    const codes = rows?.map((row: any) => row.code);
    setCodes(codes);
  };

  const handleSave = () => {
    onSubmit({
      workerLogsEnrollDailyWorkersPostRequest: {
        dailyWorkerGroup: codes,
      },
    });
    closeModal({});
  };

  return (
    <AssignWorker.Template onSubmit={handleSave}>
      <AssignWorker.SubTitle>
        {equipmentName} {""}
        {today}
      </AssignWorker.SubTitle>
      <AssignWorker.Table
        columns={columns}
        data={data ?? []}
        onRowSelection={onRowSelection}
        localization={{
          noRecordsToDisplay: !equipmentCode
            ? COMMON_TEXT.DATA_EMPTY.NO_EQUIPMENT
            : COMMON_TEXT.DATA_EMPTY.NO_DATA,
        }}
      />
    </AssignWorker.Template>
  );
};
