import { useAuthWhoamiGet } from "@/api/useAuthWhoamiGet";
import { FormNumberInput } from "@/components/atom/numberInput/form/FormNumberInput";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import { MultiBarcodeInput } from "@/components/molecule/multiBarcodeInput/MultiBarcodeInput";
import { InventoriesAutoComplete } from "@/components/organism/autoComplete/inventory/inventories-autoComplete";
import { LocationAutoComplete } from "@/components/organism/autoComplete/location/location-autoComplate";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useInventoriesFormFetch } from "@/fetch/form/useInventoriesFormFetch";
import { useTransferAllHandler } from "@/hooks/handler/useTransferAllHandler";
import { customNotification } from "@/utils/notificationShow";
import { Flex, Pagination } from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner,
  WmsBatchPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef, MRT_Row } from "mantine-react-table";
import { useEffect } from "react";

const TransferAll = Object.assign(
  {},
  {
    Template: FormTemplate,
    SubTitle: FormSubTitle,
    Description: FormDescription,
    TextInput: FormTextInput,
    NumberInput: FormNumberInput,
    BarcodeInput: MultiBarcodeInput,
    Table: MantineSelectableTable,
    InventoriesAutoComplete: InventoriesAutoComplete,
    LocationAutoComplete: LocationAutoComplete,
    Pagination: Pagination,
  }
);

export const TransferAllForm = () => {
  const { closeModal } = useModal();
  const { data: userData, refetch } = useAuthWhoamiGet();
  const { onSubmit } = useTransferAllHandler();
  const fromLocationCode = userData?.fromLocationCode;
  const toLocationCode = userData?.toLocationCode;

  const form = useForm<
    WmsBatchPostRequest & { useBarcode: boolean; barcodeInput: string }
  >({
    initialValues: {
      useBarcode: false,
      barcodeInput: "",
      fromLocationCode: "",
      toLocationCode: "",
      items: [],
      additional: {},
    },
  });

  const { data } = useInventoriesFormFetch({
    itemCodes: undefined,
    locationCode: form.values.fromLocationCode,
    lotId: form.values.barcodeInput,
    enabled: Boolean(form.values.barcodeInput && form.values.fromLocationCode),
  });

  const columns = () =>
    [
      {
        accessorKey: "order",
        header: COMMON_TEXT.COLUMN.ORDER,
      },
      {
        accessorKey: "lotId",
        header: COMMON_TEXT.COLUMN.LOT_ID,
      },
      {
        accessorKey: "lotName",
        header: COMMON_TEXT.COLUMN.LOT_NAME,
      },
      {
        accessorKey: "itemCode",
        header: COMMON_TEXT.COLUMN.ITEM_CODE,
      },
      {
        accessorKey: "itemName",
        header: COMMON_TEXT.COLUMN.ITEM_NAME,
      },
      {
        accessorKey: "spec",
        header: COMMON_TEXT.COLUMN.SPEC,
      },
      {
        accessorKey: "quantity",
        header: COMMON_TEXT.COLUMN.QUANTITY,
        Cell: ({ cell }) => {
          return (
            <FormNumberInput
              {...form.getInputProps(`items.${cell.row.index}.quantity`)}
              value={Number(cell.getValue())}
            />
          );
        },
      },
    ] as MRT_ColumnDef<any>[];

  const handleOnSubmit = () => {
    const request = {
      fromLocationCode: form.values.fromLocationCode as string,
      toLocationCode: form.values.toLocationCode as string,
      items: form.values.items?.map((item) => ({
        lotId: Number(item.lotId),
        quantity: String(item.quantity),
      })),
      additional: { ...form.values.additional },
    };

    onSubmit({ wmsBatchPostRequest: request });
  };

  const checkDuplicateLot = (value: string) => {
    if (form.values.items.some((data: any) => data.lotId === Number(value))) {
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.DUPLICATE_LOT,
      });
      form.setFieldValue("barcodeInput", "");
      return true;
    }
    return false;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      if (!form.values.barcodeInput) return;
      if (!fromLocationCode && !form.values.fromLocationCode) {
        customNotification.error({
          message: COMMON_TEXT.NOTIFICATION.NO_LOCATION,
        });
        return;
      }
      if (checkDuplicateLot(form.values.barcodeInput)) return;
      form.setFieldValue("barcodeInput", form.values.barcodeInput);
      form.setFieldValue("useBarcode", true);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (!inputValue) return;
    if (!fromLocationCode && !form.values.fromLocationCode) {
      customNotification.error({
        message: COMMON_TEXT.NOTIFICATION.NO_LOCATION,
      });
      return;
    }
    if (checkDuplicateLot(inputValue)) return;
    form.setFieldValue("barcodeInput", inputValue);
    form.setFieldValue("useBarcode", true);
  };

  const handleRowDelete = (row: MRT_Row<any>) => {
    form.removeListItem("items", row.index);
  };

  useEffect(() => {
    if (!data) return;

    if (form.values.useBarcode && form.values.barcodeInput) {
      const noData = !data?.data?.rows?.length;

      if (noData) {
        form.setFieldValue("useBarcode", false);
        form.setFieldValue("barcodeInput", "");
        customNotification.error({
          message: COMMON_TEXT.NOTIFICATION.LOT_NOT_FOUND,
        });
        return;
      }

      const { lotId, itemCode, itemName, lot, locationCode, quantity } = data
        ?.data
        ?.rows?.[0] as ItemsGet200ResponseRowsInnerLotsInnerInventoriesInner;
      form.setFieldValue("useBarcode", false);
      form.setFieldValue("barcodeInput", "");
      form.insertListItem("items", {
        lotName: lot?.name,
        lotExpiration: lot?.expiration,
        lotId,
        itemCode,
        itemName,
        locationCode,
        quantity,
      });
    }
  }, [form.values.useBarcode, data]);

  useEffect(() => {
    if (fromLocationCode) {
      form.setValues((prevValues) => ({
        ...prevValues,
        fromLocationCode: fromLocationCode,
      }));
    }

    if (toLocationCode) {
      form.setValues((prevValues) => ({
        ...prevValues,
        toLocationCode: toLocationCode,
      }));
    }
  }, [fromLocationCode, toLocationCode]);

  return (
    <TransferAll.Template
      onSubmit={handleOnSubmit}
      onCancel={() => closeModal({})}
      label="벌크 이동"
    >
      <TransferAll.SubTitle>
        {COMMON_TEXT.FORM.RAW_MATERIAL_ALL_SUBTITLE}
      </TransferAll.SubTitle>
      <TransferAll.Description>
        {COMMON_TEXT.DESCRIPTION.TRANSFER_ALL_DESC}
      </TransferAll.Description>
      <Flex justify={"space-between"}>
        <Flex gap={"md"}>
          <TransferAll.LocationAutoComplete
            dropdownPosition="bottom"
            defaultValue={fromLocationCode}
            label={COMMON_TEXT.LABEL.DELIVERY_LOCATION_CODE}
            {...form.getInputProps("fromLocationCode")}
          />
          <TransferAll.LocationAutoComplete
            data-autofocus={!toLocationCode}
            dropdownPosition="bottom"
            label={COMMON_TEXT.LABEL.TARGET_LOCATION_CODE}
            {...form.getInputProps("toLocationCode")}
          />
        </Flex>
        <TransferAll.BarcodeInput
          label={COMMON_TEXT.LABEL.INPUT_BARCODE_INFORM}
          handleBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...form.getInputProps("barcodeInput")}
        />
      </Flex>
      <TransferAll.Table
        localization={{
          noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_LOT_INFORM,
        }}
        onClickDelete={handleRowDelete}
        enableRowActions={true}
        enableRowSelection={false}
        columns={columns()}
        data={form.values.items.map((data, index: number) => ({
          order: index + 1,
          ...data,
        }))}
      />
    </TransferAll.Template>
  );
};
