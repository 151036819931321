import { WorksItemGet200ResponseRowsInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";

export const trackingStatusInKorean: {
  [key in WorksItemGet200ResponseRowsInnerTrackingStatusEnum]?: string;
} = {
  WAITING: "작업대기",
  PAUSED: "일시정지",
  WORKING: "작업중",
  DONE: "작업완료",
};

export function getTrackingStatusInKorean(
  trackingStatus: WorksItemGet200ResponseRowsInnerTrackingStatusEnum | undefined
): string {
  if (trackingStatus === undefined) {
    return "알 수 없음";
  }
  return trackingStatusInKorean[trackingStatus] || "알 수 없음";
}
