import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useInputLogsFormFetch } from "@/fetch/form/useInputLogsFormFetch";
import { usePaginationStore } from "@/store/pagenation.store";
import { Flex, Pagination } from "@mantine/core";
import { WorkLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect } from "react";

const InputLogs = Object.assign(
  {},
  {
    Template: FormTemplate,
    Description: FormDescription,
    Table: MantineSelectableTable,
    Pagination: Pagination,
  }
);

type InputLogsFormProps = {
  workIds?: number[];
};

export const InputLogsForm = ({ workIds }: InputLogsFormProps) => {
  const { data, Fetcher: InputLogsFormFetcher } = useInputLogsFormFetch({
    workIds: workIds,
  });
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));

  const inputLogs = data?.data;

  const columns = () => [
    {
      accessorKey: "itemCode",
      header: COMMON_TEXT.COLUMN.ITEM_CODE,
    },
    {
      accessorKey: "lotName",
      header: COMMON_TEXT.COLUMN.LOT_NAME,
    },
    {
      accessorKey: "lotExpiration",
      header: COMMON_TEXT.COLUMN.LOT_EXPIRATION_DATE,
    },
    {
      accessorKey: "locationName",
      header: COMMON_TEXT.COLUMN.RELEASE_LOCATION,
    },
    {
      accessorKey: "quantity",
      header: COMMON_TEXT.COLUMN.QUANTITY,
    },
    {
      accessorKey: "inputLocation",
      header: COMMON_TEXT.COLUMN.INPUT_LOCATION,
    },
    {
      accessorKey: "userCode",
      header: COMMON_TEXT.COLUMN.WORKER,
    },
    {
      accessorKey: "createdAt",
      header: COMMON_TEXT.COLUMN.CREATED_AT,
    },
  ];

  const convertInputsLogsToTableData = inputLogs?.rows?.map(
    (row: WorkLogsGet200ResponseRowsInner) => {
      const itemCode = row.logData?.incoming?.itemCode ?? "-";
      const lotName = row.logData?.incoming?.lotData?.name ?? "-";
      const lotExpiration = row.logData?.incoming?.lotData?.expiration
        ? dayjs(row.logData?.incoming?.lotData?.expiration).format("YYYY-MM-DD")
        : "-";
      const locationName = row.logData?.outgoing?.locationData?.name ?? "-";
      const quantity = `${row.logData?.incoming?.quantity ?? "-"}${
        row.unitText ?? "EA"
      }`;
      const inputLocation = row.logData?.incoming?.locationData?.name ?? "-";
      const userCode = row.logData?.incoming?.userCode ?? "-";
      const createdAt = row.logData?.incoming?.createdAt
        ? dayjs(row.logData?.incoming?.createdAt).format("YYYY-MM-DD HH:mm:ss")
        : "-";

      return {
        itemCode,
        lotName,
        lotExpiration,
        locationName,
        quantity,
        inputLocation,
        userCode,
        createdAt,
      };
    }
  );

  useEffect(() => {
    setPage(1);
  }, []);

  return (
    <InputLogsFormFetcher>
      <InputLogs.Template isButtonDisabled={true}>
        <InputLogs.Description>
          {COMMON_TEXT.DESCRIPTION.INPUT_LOGS_DESC}
        </InputLogs.Description>
        <InputLogs.Table
          enableRowSelection={false}
          data={convertInputsLogsToTableData || []}
          columns={columns()}
        />
        <Flex mt={"sm"} justify={"center"}>
          <InputLogs.Pagination
            total={inputLogs?.totalPages ?? 0}
            value={currentPage}
            onChange={(page) => setPage(page)}
          />
        </Flex>
      </InputLogs.Template>
    </InputLogsFormFetcher>
  );
};
