import { equipments } from "@/api/equipment/useEquipmentQuery";
import useFetcher from "@/hooks/useFetcher";

type useEquipmentFormFetchType = {
  code?: string;
};

export const useEquipmentFormFetch = ({ code }: useEquipmentFormFetchType) => {
  const query = code
    ? {
        $and: [{ code: { $eq: code } }],
      }
    : {};

  const { queryKey, queryFn } = equipments.find({
    query: query,
    populate: ["workerGroupArray"],
  });

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher(
    {
      queryKey: queryKey,
      queryFn: queryFn,
    },
    {
      enabled: !!code,
    }
  );

  return { data, Fetcher };
};
