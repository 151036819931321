import { BaseButton } from "@/components/atom/button/BaseButton";
import { FormDescription } from "@/components/atom/title/form/FormDescription";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { FormTitle } from "@/components/atom/title/form/FormTitle";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useInventoriesFetch } from "@/fetch/page/work/useInventoriesFetch";
import { useWorkFetch } from "@/fetch/page/work/useWorkFetch";
import { useDiligenceHandler } from "@/hooks/handler/useDiligenceHandler";
import { usePerformanceHandler } from "@/hooks/handler/usePerformanceHandler";
import { useNonStandardStore } from "@/store/nonStandard.store";
import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { useMemo } from "react";

const NonStandardComplete = Object.assign(
  {},
  {
    Template: FormTemplate,
    Title: FormTitle,
    Description: FormDescription,
    SubTitle: FormSubTitle,
    Button: BaseButton,
    Table: MantineDataTable,
  }
);

export const NonStandardCompleteForm = () => {
  const { data, selectedRowIds, setSelectedRowIds } = useNonStandardStore();
  const { closeModal } = useModal();
  const { data: work } = useWorkFetch();
  const workData = work?.data;
  const { data: inventory } = useInventoriesFetch({
    workData: workData as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const { handleGroupPerformance, handleGroupFakeDefect } =
    usePerformanceHandler();
  const { onSubmit } = useDiligenceHandler();

  const selectedRow = selectedRowIds?.map((id) =>
    data.find((value) => value.id === id)
  );

  const inventories = inventory?.data;

  const convertedData = [
    {
      remainRawMaterial: inventories?.[0]?.quantity,
      lotNumber: inventories?.[0]?.lot?.name,
      expectedConsumedRawMaterial: selectedRow
        ?.map((row) =>
          Number(
            row?.end
              ? row?.end
              : row?.summary?.end === "0"
              ? row?.targetQuantity
              : row?.summary?.end
          )
        )
        ?.reduce((acc, cur) => acc + cur, 0),
    },
  ];

  const columns = useMemo<MRT_ColumnDef[]>(
    () => [
      {
        accessorKey: "remainRawMaterial",
        header: COMMON_TEXT.COLUMN.REMAIN_RAW_MATERIAL,
      },
      {
        accessorKey: "lotNumber",
        header: COMMON_TEXT.COLUMN.LOT_NUMBER,
      },
      {
        accessorKey: "expectedConsumedRawMaterial",
        header: COMMON_TEXT.COLUMN.EXPECTED_CONSUMED_RAW_MATERIAL,
      },
    ],
    []
  );

  // 실적 수량 저장하는 문제에 대해 논의해봐야겠다.

  const handleProductionInput = () => {
    if (!selectedRow) return;

    const workId = selectedRow.map((value) => value?.id) as number[];
    const quantity = selectedRow
      .map((value) =>
        value?.end
          ? value?.end
          : value?.summary?.end === "0"
          ? value?.targetQuantity
          : value?.summary?.end
      )
      .map((value) => value?.toString()) as string[];
    const warnInsufficiencies = selectedRow.map(() => true);
    const defectCount = selectedRow.map(
      (value) => value?.defectCount?.toString() ?? "0"
    );

    handleGroupPerformance({
      workIds: workId,
      accQuantities: quantity,
      warnInsufficiencies: warnInsufficiencies,
    }).then(() => {
      const hasDefects = defectCount.some((value) => value !== "0");
      if (hasDefects) {
        handleGroupFakeDefect({
          workIds: workId,
          quantities: defectCount,
        });
      }
    });
    closeModal({});
  };

  const handleRawMaterialAdjustment = async () => {
    await onSubmit({
      wmsDiligencePostRequest: {
        itemCode: inventories?.[0]?.itemCode as string,
        locationCode: inventories?.[0]?.locationCode as string,
        quantity: String(
          selectedRow
            ?.map((row) =>
              Number(
                row?.end
                  ? row?.end
                  : row?.summary?.end === "0"
                  ? row?.targetQuantity
                  : row?.summary?.end
              )
            )
            ?.reduce((acc, cur) => acc + cur, 0)
        ),
        lotId: inventories?.[0]?.lotId,
        lotInfo: {
          name: inventories?.[0]?.lot?.name,
          expiration: inventories?.[0]?.lot?.expiration,
        },
        actionFrom: "POP",
      },
    });

    handleProductionInput();
  };

  return (
    <NonStandardComplete.Template isButtonDisabled={true}>
      <NonStandardComplete.Table
        mantineTableHeadCellProps={{
          sx: {
            backgroundColor: "#FFFFFF",
          },
        }}
        mantineTableBodyCellProps={{
          style: {
            backgroundColor: "#FFFFFF",
            color: "#000000",
          },
        }}
        columns={columns}
        data={convertedData}
      />
      <NonStandardComplete.Description>
        *{COMMON_TEXT.DESCRIPTION.PRODUCTION_INPUT_DESC}
      </NonStandardComplete.Description>
      <NonStandardComplete.Description>
        *{COMMON_TEXT.DESCRIPTION.RAW_MATERIAL_ADJUSTMENT_AND_INPUT_DESC}
      </NonStandardComplete.Description>
      <NonStandardComplete.SubTitle
        c="green"
        align="center"
        size={"1.2rem"}
        fw={"bold"}
      >
        {COMMON_TEXT.DESCRIPTION.PRODUCTION_INPUT_SAVE_DESC}
      </NonStandardComplete.SubTitle>
      <Flex justify={"space-between"}>
        <NonStandardComplete.Button
          onClick={() => closeModal({})}
          variant="outline"
          color="gray"
        >
          {COMMON_TEXT.BUTTON.CANCEL}
        </NonStandardComplete.Button>
        <Flex gap={"md"}>
          <NonStandardComplete.Button
            color="cyan"
            onClick={handleProductionInput}
          >
            {COMMON_TEXT.BUTTON.PRODUCTION_INPUT}
          </NonStandardComplete.Button>
          <NonStandardComplete.Button
            color="indigo"
            onClick={handleRawMaterialAdjustment}
          >
            {COMMON_TEXT.BUTTON.RAW_MATERIAL_ADJUSTMENT_AND_INPUT}
          </NonStandardComplete.Button>
        </Flex>
      </Flex>
    </NonStandardComplete.Template>
  );
};
