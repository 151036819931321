import { workLogs } from "@/api/logs/useWorksLogsQuery";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import useFetcher from "@/hooks/useFetcher";
import { usePaginationStore } from "@/store/pagenation.store";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";

type useWorkLogsFormFetchType = {
  workIds?: number[];
};

export const useWorkLogsFormFetch = ({ workIds }: useWorkLogsFormFetchType) => {
  const { workId } = useLocalStorage();
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));

  const query: any = {
    $and: [
      { parentWorkLogId: { $eq: null } },
      {
        $or: [
          {
            workLogType: {
              $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.DEFECT,
            },
          },
          {
            workLogType: {
              $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT,
            },
          },
          {
            workLogType: {
              $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.PERFORMANCE,
            },
          },
          {
            workLogType: {
              $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.RECYCLE,
            },
          },
          {
            workLogType: {
              $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.SPLIT_LOT,
            },
          },
          {
            workLogType: {
              $eq: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.CORRECTION,
            },
          },
        ],
      },
      {
        $or: [
          { description: { $ne: "ACTION" } },
          { description: { $eq: null } },
        ],
      },
    ],
  };

  if (!workIds?.length && workId) {
    query.$and.push({
      workId,
    });
  }

  if (workIds?.length) {
    query.$and.push({
      workId: { $in: workIds },
    });
  }

  const { queryKey, queryFn } = workLogs.get({
    query: query,
    sort: "-id",
    page: currentPage,
    populate: [
      "itemUnit",
      "defectName",
      "creatorUser",
      "alreadyCanceledWorkLog",
    ],
  });

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
