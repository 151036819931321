import { COMMON_TEXT } from "@/constants/text";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";

const TABLE_BACKGROUND_COLOR = "#161B21";
const TABLE_BORDER = "1px solid white";
const HEADER_TEXT_COLOR = "gray";
const ROW_TEXT_COLOR = "#FFFFFF";

interface MantineDataTableProps<TData extends Record<string, any>>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  onRowClick?: (row: TData) => void;
  data: TData[];
  maxHeight?: string | number;
}

export const MantineDataTable = <TData extends Record<string, any>>({
  columns,
  data,
  onRowClick,
  maxHeight,
  ...etcProps
}: MantineDataTableProps<TData>) => {
  const table = useMantineReactTable<TData>({
    columns,
    data: data,
    enableRowSelection: false,
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    positionActionsColumn: "last",
    mantineTableBodyRowProps: (row) => ({
      sx: {
        color: ROW_TEXT_COLOR,
        cursor: "pointer",
        "&:hover": {
          opacity: 0.8,
        },
      },
      onClick: () => {
        const rowData = row.row.original;
        onRowClick && onRowClick(rowData);
      },
    }),
    localization: {
      noRecordsToDisplay: COMMON_TEXT.DATA_EMPTY.NO_DATA,
    },
    mantineTableHeadCellProps: {
      style: {
        background: TABLE_BACKGROUND_COLOR,
        color: HEADER_TEXT_COLOR,
        fontSize: "0.8rem",
      },
      sx: {
        border: `${TABLE_BORDER} !important`,
      },
    },
    mantineTableBodyCellProps: ({ cell }) => {
      const unitList = ["EA", "KG", "CM"];
      const cellValue = cell.getValue();
      const isUnit =
        typeof cellValue === "string" &&
        unitList.some((unit) =>
          cellValue?.toLowerCase().includes(unit.toLowerCase())
        );
      const numberValue = isUnit
        ? parseFloat(cellValue.replace(/[^\d.-]/g, ""))
        : NaN;
      const isNumber = !isNaN(numberValue);
      return {
        style: {
          fontSize: "0.7rem",
          textAlign: isNumber ? "right" : "left",
          background: TABLE_BACKGROUND_COLOR,
        },
        sx: {
          border: `${TABLE_BORDER} !important`,
        },
      };
    },
    mantineTableProps: {
      sx: {
        "tbody tr:nth-of-type(1) > td": {
          fontSize: "1.0rem",
          backgroundColor: TABLE_BACKGROUND_COLOR,
        },
      },
    },
    mantineTableContainerProps: {
      sx: {
        maxHeight: maxHeight ?? "100%",
        overflowX: "auto",
      },
    },
    enableStickyHeader: true,
    defaultColumn: {
      size: 10,
    },

    ...etcProps,
  });

  return <MantineReactTable table={table} />;
};
