import { ItemType } from "@/components/organism/nonStandard/NonStandardTable";
import create from "zustand";

type NonstandardStoreState = {
  data: ItemType[];
  selectedRowIds: number[] | null;
  setData: (data: ItemType[]) => void;
  setSelectedRowIds: (id: number[] | null) => void;
  updateRow: (id: number, updates: any) => Promise<void>;
};

export const useNonStandardStore = create<NonstandardStoreState>(
  (set, get) => ({
    data: [],
    selectedRowIds: null,
    setData: (data: ItemType[]) => set({ data }),
    setSelectedRowIds: (ids) => set({ selectedRowIds: ids }),
    updateRow: async (id, updates) => {
      set((state) => ({
        data: state.data.map((item) =>
          item.id === id ? { ...item, ...updates } : item
        ),
      }));
    },
  })
);
