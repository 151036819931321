import { StatusButtonGroup } from "@/components/molecule/statusButtonGroup/StatusButtonGroup";
import InfoSection from "@/components/organism/nonStandard/InfoSection";
import { NonStandardHeader } from "@/components/organism/nonStandard/NonStandardHeader";
import NonStandardTable from "@/components/organism/nonStandard/NonStandardTable";

import { WorkInventoryInform } from "@/components/organism/work/original/WorkInventoryInform";
import { WorkLocationInform } from "@/components/organism/work/original/WorkLocationInform";
import PageTemplate from "@/components/template/page/PageTemplate";
import { useWorkFetch } from "@/fetch/page/work/useWorkFetch";
import useSocket from "@/hooks/useSocket.hook";
import { useEquipmentStore } from "@/store/equipment.store";
import { useWorkDataStore } from "@/store/work.store";
import { useEffect } from "react";

const NonStandard = Object.assign(
  {},
  {
    Header: NonStandardHeader,
    InfoSection: InfoSection,
    Template: PageTemplate,
    Table: NonStandardTable,
    StatusButton: StatusButtonGroup,
    Inventory: WorkInventoryInform,
    Location: WorkLocationInform,
  }
);

export const NonStandardPage = () => {
  useSocket();

  const { data } = useWorkFetch();
  const workData = data?.data;
  const { name, code } = workData?.equipment ?? {};
  const { setEquipment } = useEquipmentStore((state) => ({
    setEquipment: state.setEquipment,
  }));
  const { setWorkData } = useWorkDataStore((state) => ({
    setWorkData: state.setWorkData,
  }));

  useEffect(() => {
    if (!code || !name) return;
    setEquipment({
      name: name,
      code: code,
    });
    setWorkData({ work: workData });
  }, [workData]);

  return (
    <NonStandard.Template>
      <div style={{ width: "100%" }}>
        <NonStandard.Header />
        <NonStandard.InfoSection />
        <div style={{ marginBottom: "1rem" }}>
          <NonStandard.Table />
        </div>
        <NonStandard.Inventory />
        <NonStandard.Location />
      </div>
    </NonStandard.Template>
  );
};
