import { AppShellAside } from "@/components/organism/appShell/appShellAside/AppShellAside";
import { AppShellHeader } from "@/components/organism/appShell/appShellHeader/AppShellHeader";
import { AssignWorkerForm } from "@/components/organism/form/AssignWorkerForm";
import { AssignWorkerPage } from "@/components/page/original/assignWoker";
import { NonStandardPage } from "@/components/page/original/nonStandard";
import { WorkPage as OriginalWorkPage } from "@/components/page/original/workPage";
import { WorksPage as OriginalWorksPage } from "@/components/page/original/worksPage";
import { InspectRecordsPage } from "@/components/page/refactor/inspectRecordsPage";
import { InventoriesPage } from "@/components/page/refactor/inventoriesPage";
import { LoginPage } from "@/components/page/refactor/loginPage";
import { WorkPage as RefactorWorkPage } from "@/components/page/refactor/workPage";
import { WorksPage as RefactorWorksPage } from "@/components/page/refactor/worksPage";
import { customFunctions } from "@/config/customFunctions";

import { COMMON_TEXT } from "@/constants/text";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useModal } from "@/context/ModalStackManager";
import { useWorkerLogFetch } from "@/fetch/page/assignWoker/useWorkerLogFetch";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { AppShell } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export default function App() {
  const refactorDesign = customFunctions.ADD_REFACTOR_DESIGN;

  const routes = [
    {
      path: "/works",
      name: COMMON_TEXT.PAGE.WORKS,
      element: refactorDesign ? <RefactorWorksPage /> : <OriginalWorksPage />,
    },
    {
      path: "/work/:workId",
      name: COMMON_TEXT.PAGE.POP,
      element: refactorDesign ? <RefactorWorkPage /> : <OriginalWorkPage />,
    },
    {
      path: "/nonStandardWork/:workId",
      name: COMMON_TEXT.PAGE.NON_STANDARD_WORK,
      element: <NonStandardPage />,
    },
    {
      path: "/inventory",
      name: COMMON_TEXT.PAGE.INVENTORY,
      element: <InventoriesPage />,
    },
    {
      path: "/inspections",
      name: COMMON_TEXT.PAGE.INSPECT_LOG,
      element: <InspectRecordsPage />,
    },
    {
      path: "/assign",
      name: COMMON_TEXT.PAGE.INSPECT_LOG,
      element: <AssignWorkerPage />,
    },
  ];

  const { isAuthenticated } = useLocalStorage();
  const isUserAuthenticated = JSON.parse(isAuthenticated || "false");
  const { openModal } = useModal();
  const [opened, setOpened] = useState(false);
  const [asideOpened, setAsideOpened] = useState(false);
  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const isAdmin = userData?.isAdmin;
  const startOfDay = dayjs().startOf("day").toISOString(); // 00:00:00
  const endOfDay = dayjs().endOf("day").toISOString(); // 23:59:59

  const { data: workerLogData } = useWorkerLogFetch({
    query: {
      $and: [{ createdAt: { $gte: startOfDay, $lt: endOfDay } }],
    },
  });

  const isTodayEnrollWorker = workerLogData?.data?.rows?.length !== 0;

  useEffect(() => {
    if (!isTodayEnrollWorker) {
      if (isAdmin) {
        openModal(
          <AssignWorkerForm />,
          " ",
          COMMON_TEXT.FORM.TODAY_ENROLL_WORKER
        );
      }
    }
  }, [isAdmin, isTodayEnrollWorker]); // isAdmin이 변경될 때만 실행됨

  return isUserAuthenticated ? (
    <AppShell
      styles={{
        main: {
          paddingRight: asideOpened
            ? "1rem"
            : `calc(var(--mantine-aside-width, 0px) + 1rem)`,
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      header={
        <AppShellHeader
          opened={opened}
          asideOpened={asideOpened}
          setOpened={setOpened}
          setAsideOpened={setAsideOpened}
        />
      }
      aside={asideOpened ? <AppShellAside /> : <></>}
    >
      <Routes>
        <Route path="/" element={<Navigate to="/works" />} />
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </AppShell>
  ) : (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      {/* 인증되지 않은 사용자는 항상 로그인 페이지로 리다이렉트 */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}
