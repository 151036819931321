import { HeaderTabs } from "@/components/molecule/headerTabs/HeaderTabs";
import { customFunctions } from "@/config/customFunctions";
import { Burger, Header } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { IconSettings } from "@tabler/icons-react";

interface AppShellHeaderProps {
  opened: boolean;
  asideOpened: boolean;
  setOpened: (opened: boolean) => void;
  setAsideOpened: (asideOpened: boolean) => void;
}

export const AppShellHeader = ({
  opened,
  asideOpened,
  setOpened,
  setAsideOpened,
}: AppShellHeaderProps) => {
  const isSmallScreen = useMediaQuery("(max-width: 768px)");

  return isSmallScreen && customFunctions.ADD_REFACTOR_DESIGN ? (
    <Header height={{ base: 50, md: 60 }} p="md" bg={"#212529"}>
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <Burger
          opened={opened}
          onClick={() => setOpened(!opened)}
          size="sm"
          color={"gray"}
          mr="xl"
        />
      </div>
    </Header>
  ) : (
    <Header
      pl={"xl"}
      pr={"xl"}
      height={{ base: 50, md: 60 }}
      bg={"#212529"}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <HeaderTabs
        tabList={[
          { value: "works", label: "작업지시" },
          { value: "work/:workId", label: "POP" },
          { value: "nonStandardWork/:workId", label: "비규격 POP" },
          { value: "inventory", label: "재고" },
          { value: "inspections", label: "검사기록" },
          { value: "assign", label: "작업자 지정" },
        ]}
      />
      <IconSettings
        size={"25"}
        color="white"
        onClick={() => setAsideOpened(!asideOpened)}
      />
    </Header>
  );
};
