import { correct } from "@/api/correct/useEquipmentCorrection";
import { mutateDefect } from "@/api/defects/useDefectsQuery";
import { EQUIPMENTS_KEY } from "@/api/equipment/useEquipmentQuery";
import { mutateFlush } from "@/api/flush/useFlushQuery";
import { INVENTORIES_KEY } from "@/api/inventories/useInventoriesQuery";
import { mutatePerformance } from "@/api/performance/useWorkPerformanceQuery";
import { mutateWorks, WORK_KEY } from "@/api/work/useWorkQuery";
import { WORK_LOGS_KEY, workLogsMutate } from "@/api/workLogs/useWorkLogsQuery";
import trackingActions from "@/api/workTracking/useWorkTarckingQuery";
import { COMMON_TEXT } from "@/constants/text";
import { customNotification } from "@/utils/notificationShow";
import {
  DefaultApiWmsFlushPutRequest,
  DefaultApiWorksWorkIdPutRequest,
  ProductionActionApiEquipmentsCorrectionPutRequest,
  ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest,
  ProductionActionApiWorksGroupDefectPutRequest,
  ProductionActionApiWorksGroupFakeDefectPutRequest,
  ProductionActionApiWorksGroupPerformancePutRequest,
  ProductionActionApiWorksWorkIdDefectPutRequest,
  ProductionActionApiWorksWorkIdPerformancePutRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingCancelEndPostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest,
  ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

export const usePutWorkData = () => {
  const queryClient = useQueryClient();

  const { mutate: performanceMutate, mutateAsync: performanceMutateAsync } =
    useMutation(
      (params: ProductionActionApiWorksWorkIdPerformancePutRequest) =>
        mutatePerformance.update(params).mutationFn(undefined),

      {
        onSuccess: () => {
          queryClient.invalidateQueries([WORK_KEY]);
          queryClient.invalidateQueries([INVENTORIES_KEY]);
          queryClient.invalidateQueries([WORK_LOGS_KEY]);
          queryClient.invalidateQueries([EQUIPMENTS_KEY]);
          customNotification.success({
            message: COMMON_TEXT.NOTIFICATION.PERFORMANCE_SUCCESS,
          });
        },
      }
    );

  const { mutate: defectMutate, mutateAsync: defectMutateAsync } = useMutation(
    (params: ProductionActionApiWorksWorkIdDefectPutRequest) =>
      mutateDefect.worksWorkIdDefectPut(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.DEFECT_SUCCESS,
        });
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([EQUIPMENTS_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.DEFECT_FAIL,
        });
      },
    }
  );

  const { mutate: settingMutate } = useMutation(
    (params: DefaultApiWorksWorkIdPutRequest) =>
      mutateWorks.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.SETTING_SUCCESS,
        });
        queryClient.invalidateQueries([WORK_KEY]);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.SETTING_FAIL,
        });
      },
    }
  );

  const { mutate: flushMutate } = useMutation(
    (params: DefaultApiWmsFlushPutRequest) =>
      mutateFlush.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.FLUSH_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.FLUSH_FAIL,
        });
      },
    }
  );

  const { mutate: startMutate } = useMutation(
    (
      params: ProductionTimeTrackingActionApiWorksWorkIdTrackingStartPostRequest
    ) => trackingActions.start(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.START_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.START_FAIL,
        });
      },
    }
  );

  const { mutate: pauseMutate } = useMutation(
    (
      params: ProductionTimeTrackingActionApiWorksWorkIdTrackingPausePostRequest
    ) => trackingActions.pause(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.PAUSE_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.PAUSE_FAIL,
        });
      },
    }
  );

  const { mutate: endMutate } = useMutation(
    (
      params: ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest
    ) => trackingActions.end(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.END_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ?? COMMON_TEXT.NOTIFICATION.END_FAIL,
        });
      },
    }
  );

  const { mutate: resumeMutate } = useMutation(
    (
      params: ProductionTimeTrackingActionApiWorksWorkIdTrackingResumePostRequest
    ) => trackingActions.resume(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.RESUME_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.RESUME_FAIL,
        });
      },
    }
  );

  const { mutate: correctMutate } = useMutation(
    (params: ProductionActionApiEquipmentsCorrectionPutRequest) =>
      correct.put(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CORRECT_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CORRECT_FAIL,
        });
      },
    }
  );

  const { mutate: cancelMutate } = useMutation(
    (params: ProductionActionApiWorkLogsWorkLogIdCancelInputPutRequest) =>
      workLogsMutate.cancelInput(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_SUCCESS,
        });
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_FAIL,
        });
      },
    }
  );

  const { mutate: cancelEndMutate } = useMutation(
    (
      params: ProductionTimeTrackingActionApiWorksWorkIdTrackingCancelEndPostRequest
    ) => trackingActions.cancelEnd(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.CANCEL_END_SUCCESS,
        });
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.CANCEL_END_FAIL,
        });
      },
    }
  );

  const { mutate: groupPerformanceMutate } = useMutation(
    (params: ProductionActionApiWorksGroupPerformancePutRequest) =>
      mutatePerformance.group(params).mutationFn(undefined),

    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        queryClient.invalidateQueries([EQUIPMENTS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.PERFORMANCE_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.PERFORMANCE_FAIL,
        });
      },
    }
  );

  const { mutate: groupDefectMutate } = useMutation(
    (params: ProductionActionApiWorksGroupDefectPutRequest) =>
      mutateDefect.group(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([EQUIPMENTS_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.DEFECT_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.DEFECT_FAIL,
        });
      },
    }
  );

  const { mutate: groupDefectFakeMutate } = useMutation(
    (params: ProductionActionApiWorksGroupFakeDefectPutRequest) =>
      mutateDefect.fake(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORK_KEY]);
        queryClient.invalidateQueries([EQUIPMENTS_KEY]);
        queryClient.invalidateQueries([WORK_LOGS_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.DEFECT_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.DEFECT_FAIL,
        });
      },
    }
  );

  return {
    performanceMutate,
    defectMutate,
    settingMutate,
    flushMutate,
    startMutate,
    pauseMutate,
    endMutate,
    resumeMutate,
    correctMutate,
    cancelMutate,
    defectMutateAsync,
    performanceMutateAsync,
    cancelEndMutate,
    groupPerformanceMutate,
    groupDefectMutate,
    groupDefectFakeMutate,
  };
};
