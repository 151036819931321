import { workLogs } from "@/api/logs/useWorksLogsQuery";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import useFetcher from "@/hooks/useFetcher";
import { usePaginationStore } from "@/store/pagenation.store";
import { WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum } from "@sizlcorp/sizl-api-document/dist/models";

type useInputLogsFormFetchType = {
  workIds?: number[];
};

export const useInputLogsFormFetch = ({
  workIds,
}: useInputLogsFormFetchType) => {
  const { workId } = useLocalStorage();
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));

  const query: any = {
    $and: [
      { workLogType: WorkLogsGet200ResponseRowsInnerWorkLogTypeEnum.INPUT },
    ],
  };

  if (!workIds?.length && workId) {
    query.$and.push({
      workId,
    });
  }

  if (workIds?.length) {
    query.$and.push({
      workId: { $in: workIds },
    });
  }

  const { queryKey, queryFn } = workLogs.get({
    query: query,
    page: currentPage,
    populate: [
      "downtimeReasonName",
      "defectName",
      "itemUnit",
      "alreadyCanceled",
      "alreadyCanceledWorkLog",
      "item",
    ],
    sort: "-createdAt",
  });

  // useFetcher를 사용하여 queryKey와 queryFn을 설정
  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
