import { mutateSites } from "@/api/sites/useSitesQuery";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import { TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { MasterApiSitesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useTranslation } from "react-i18next";

const SiteCreate = Object.assign(
  {},
  {
    Template: FormTemplate,
  }
);

interface SiteCreateFormProps {
  name?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export function SiteCreateForm(params: SiteCreateFormProps) {
  const { name, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate: postMutate } = useMutation(
    (params: MasterApiSitesPostRequest) =>
      mutateSites.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sites"]);
        closeModal(form.values);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.SITE_CREATE_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        if (error.response?.data.message === undefined) return;
        customNotification.success({
          message:
            error.response?.data.message ??
            COMMON_TEXT.NOTIFICATION.SITE_CREATE_SUCCESS,
        });
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      name: name ?? undefined,
    },
  });

  const onSubmit = async () => {
    postMutate({
      sitesGetRequest: {
        code: form.values.code!,
        name: name ?? form.values.name!,
      },
    });
  };

  return (
    <SiteCreate.Template onSubmit={onSubmit}>
      <TextInput
        label={COMMON_TEXT.LABEL.SITE_CODE}
        placeholder={COMMON_TEXT.PLACEHOLDER.SITE_CODE}
        withAsterisk
        {...form.getInputProps("code")}
      />
      <TextInput
        label={COMMON_TEXT.LABEL.SITE_NAME}
        placeholder={COMMON_TEXT.PLACEHOLDER.SITE_NAME}
        withAsterisk
        width={"100%"}
        {...form.getInputProps("name")}
      />
    </SiteCreate.Template>
  );
}
