import { DataBox } from "@/components/molecule/dataBox/DataBox";
import { StatusButtonGroup } from "@/components/molecule/statusButtonGroup/StatusButtonGroup";
import { ItemInformForm } from "@/components/organism/form/ItemInformForm";
import { ButtonConfig } from "@/components/organism/header/refactor/workHeader/WorkHeader";
import { BoxTemplate } from "@/components/template/box/BoxTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useStatusHandler } from "@/hooks/handler/useStatusHandler";
import { useWorkDataStore } from "@/store/work.store";
import { getWorkTrackingStatus } from "@/utils/tranferWorkStatus";
import { Badge, Flex } from "@mantine/core";
import {
  IconAlertSquareRounded,
  IconCircleArrowUpLeft,
  IconPlayerPause,
  IconPlayerPlay,
  IconPower,
} from "@tabler/icons-react";

const StatusInform = Object.assign(
  {},
  {
    Template: BoxTemplate,
    StatusButtonGroup: StatusButtonGroup,
    DataBox: DataBox,
  }
);

export const StatusInformBox = () => {
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));
  const { work } = workData;
  const workId = work?.id as number;

  const { handleStart, handleEnd, handlePause, handleResume, handleCancelEnd } =
    useStatusHandler();

  const { openModal } = useModal();

  const statusButtonConfig: ButtonConfig[] = [
    {
      condition: () =>
        work?.externalCounterSetting !== "HANDWRITING" &&
        work?.trackingStatus === "WAITING",
      buttons: [
        {
          key: "start",
          color: "green.6",
          icon: <IconPlayerPlay />,
          onClick: () => handleStart({ workId }),
          text: COMMON_TEXT.STATUS.START,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "WORKING",
      buttons: [
        {
          key: "pause",
          color: "orange.6",
          icon: <IconPlayerPause />,
          onClick: () => handlePause({ workId }),
          text: COMMON_TEXT.STATUS.PAUSED,
        },
        {
          key: "end",
          color: "red",
          icon: <IconPower />,
          onClick: () =>
            handleEnd({
              workId,
              worksWorkIdTrackingStartPostRequest: { workId: workId },
            }),
          text: COMMON_TEXT.STATUS.END,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "PAUSED",
      buttons: [
        {
          key: "resume",
          color: "green.6",
          icon: <IconPlayerPlay />,
          onClick: () => handleResume({ workId }), // ✅ 호출 명확히 처리
          text: COMMON_TEXT.STATUS.RESUME,
        },
      ],
    },
    {
      condition: () => work?.trackingStatus === "DONE",
      buttons: [
        {
          key: "cancelEnd",
          color: "blue.6",
          icon: <IconCircleArrowUpLeft />,
          onClick: () => handleCancelEnd({ workId }),
          text: COMMON_TEXT.STATUS.CANCEL_END,
        },
      ],
    },
  ];

  const openStatusInform = () => {
    openModal(<ItemInformForm />, "", COMMON_TEXT.FORM.ITEM_INFORM);
  };

  return (
    <StatusInform.Template>
      <Flex align={"center"}>
        <StatusInform.DataBox
          label={COMMON_TEXT.LABEL.CURRENT_WORK_STATUS}
          data={
            <Badge color={getWorkTrackingStatus(work?.trackingStatus).color}>
              {getWorkTrackingStatus(work?.trackingStatus).status}
            </Badge>
          }
        />
        <StatusInform.StatusButtonGroup buttonConfigs={statusButtonConfig} />
      </Flex>
      <StatusInform.DataBox
        label={COMMON_TEXT.LABEL.ROUTING_CODE}
        data={work?.routingCode}
      />
      <Flex align={"center"}>
        <StatusInform.DataBox
          label={COMMON_TEXT.LABEL.ITEM_CODE}
          data={work?.item?.code ?? "-"}
        />
        <IconAlertSquareRounded
          stroke={2}
          size={"30"}
          color="#FFFFFF"
          onClick={openStatusInform}
        />
      </Flex>
      <StatusInform.DataBox
        label={COMMON_TEXT.LABEL.ITEM_NAME}
        data={work?.item?.name ?? "-"}
      />
    </StatusInform.Template>
  );
};
