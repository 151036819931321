import DefaultInstance, { ProductionInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiDefectsDefectCodeDeleteRequest,
  DefaultApiDefectsDefectCodePutRequest,
  DefaultApiDefectsFindPostRequest,
  DefaultApiDefectsGetRequest,
  DefaultApiDefectsPostRequest,
  DefectsGet200ResponseRowsInner,
  ProductionActionApiWorksGroupDefectPutRequest,
  ProductionActionApiWorksGroupFakeDefectPutRequest,
  ProductionActionApiWorksWorkIdDefectPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { AxiosResponse } from "axios";

export const DEFECT_KEY = "defects";

export const defects = createQueryKeys(DEFECT_KEY, {
  all: null,
  get: (params: DefaultApiDefectsGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.defectsGet(query),
    };
  },
  find: (params: DefaultApiDefectsFindPostRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.defectsFindPost(query),
    };
  },
  findSelect: (params: DefaultApiDefectsFindPostRequest) => {
    return {
      queryKey: [params],
      queryFn: () =>
        DefaultInstance.defectsFindPost().then(
          (res: AxiosResponse<DefectsGet200ResponseRowsInner[]>) => res.data
        ),
    };
  },
});

export const mutateDefect = createMutationKeys("defects", {
  create: (params: DefaultApiDefectsPostRequest) => ({
    mutationKey: [params.defectsGetRequest],
    mutationFn: () => DefaultInstance.defectsPost(params),
  }),
  update: (params: DefaultApiDefectsDefectCodePutRequest) => ({
    mutationKey: [params.defectCode, params.defectsGetRequest],
    mutationFn: () => DefaultInstance.defectsDefectCodePut(params),
  }),
  delete: (params: DefaultApiDefectsDefectCodeDeleteRequest) => ({
    mutationKey: [params.defectCode],
    mutationFn: () => DefaultInstance.defectsDefectCodeDelete(params),
  }),
  worksWorkIdDefectPut: (
    params: ProductionActionApiWorksWorkIdDefectPutRequest
  ) => ({
    mutationKey: [params.workId],
    mutationFn: () => ProductionInstance.worksWorkIdDefectPut(params),
  }),
  group: (params: ProductionActionApiWorksGroupDefectPutRequest) => ({
    mutationKey: [params.worksGroupDefectPutRequest],
    mutationFn: () => ProductionInstance.worksGroupDefectPut(params),
  }),
  fake: (params: ProductionActionApiWorksGroupFakeDefectPutRequest) => ({
    mutationKey: [params],
    mutationFn: () => ProductionInstance.worksGroupFakeDefectPut(params),
  }),
});

export const defectQueryKeys = mergeQueryKeys(defects, mutateDefect);
