import { useModal } from "@/context/ModalStackManager";

import { DownTimeReasonsForm } from "@/components/organism/form/DownTimeReasonsForm";
import { customFunctions } from "@/config/customFunctions";
import { COMMON_TEXT } from "@/constants/text";
import { useInventoryHandler } from "@/hooks/handler/useInventoryHandler";
import { useNotification } from "@/hooks/useNotification";
import { useStatus } from "@/hooks/useStatus";
import { ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest } from "@sizlcorp/sizl-api-document/dist/models";

type StatusEventType = {
  workId: number;
  IsWorkOrderInProgress?: boolean;
};

export const useStatusHandler = () => {
  const { openModal, closeModal } = useModal();

  const {
    notifyPreviousOrderClosureModal,
    notifyWorkClosureModal,
    notifyCancelEnd,
  } = useNotification();

  const { handleFlush } = useInventoryHandler();

  const { onStart, onResume, onEnd, onPause, onCancelEnd } = useStatus();

  // 이전 작업 지시서가 종료를 확인하는 함수(workId 와 activeWorkId 가 다를 경우 요청한다.)
  const handlePreviousOrderClosure = async (
    IsWorkOrderInProgress: boolean | undefined
  ) => {
    if (IsWorkOrderInProgress) {
      const confirmClosure = await notifyPreviousOrderClosureModal();
      if (!confirmClosure) return false;
    }
    return true;
  };

  // 작업시작 이벤트
  const handleStart = async ({
    workId,
    IsWorkOrderInProgress,
  }: StatusEventType) => {
    const startWork = async () => {
      onStart(workId);
      closeModal({});
    };

    // 이전 작업지시서가 종료 되었는지를 묻는다.
    const PreviousOrderClosure = await handlePreviousOrderClosure(
      IsWorkOrderInProgress
    );
    if (!PreviousOrderClosure) return;

    // 플러싱 여부를 묻는다.
    if (customFunctions.ADD_FLUSHING) {
      await handleFlush();
    }
    await startWork();
  };

  // 작업재개 이벤트
  const handleResume = async ({
    workId,
    IsWorkOrderInProgress,
  }: StatusEventType) => {
    const resumeWork = async (workId: number) => {
      return onResume(workId);
    };

    // 이전 작업지시서가 종료 되었는지를 묻는다.
    const PreviousOrderClosure = await handlePreviousOrderClosure(
      IsWorkOrderInProgress
    );
    if (PreviousOrderClosure) {
      await resumeWork(workId);
    }
  };

  // 작업종료 이벤트
  const handleEnd = async ({
    workId,
    worksWorkIdTrackingStartPostRequest,
  }: ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest) => {
    const confirmWorkClosure = await notifyWorkClosureModal();
    if (confirmWorkClosure) {
      return onEnd({
        workId,
        worksWorkIdTrackingStartPostRequest,
      });
    }
  };

  // 작업일시정지 이벤트
  const handlePause = ({ workId }: StatusEventType) => {
    return openModal(
      <DownTimeReasonsForm
        onClick={(reason) => {
          onPause(workId, reason);
          closeModal({});
        }}
      />,
      "",
      COMMON_TEXT.FORM.DOWNTIME_REASONS
    );
  };

  const handleCancelEnd = async ({ workId }: StatusEventType) => {
    const result = await notifyCancelEnd();
    if (result) {
      onCancelEnd(workId);
    }
  };

  return {
    handleStart,
    handleResume,
    handleEnd,
    handlePreviousOrderClosure,
    handlePause,
    handleCancelEnd,
  };
};
