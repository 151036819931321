import { Tabs, TabsProps } from "@mantine/core";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface HeaderTabsProps {
  tabList: { value: string; label: string }[];
  styles?: TabsProps["styles"];
}

export const HeaderTabs = ({ tabList, styles }: HeaderTabsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  // 현재 경로에 따라 활성 탭을 결정하는 함수
  const activeTab = useMemo(() => {
    const path = location.pathname;
    for (let tab of tabList) {
      if (tab.value.includes("/:")) {
        // 동적 라우트인 경우, base 경로 비교 (예: "work/:workId" -> "work")
        const base = tab.value.split("/:")[0];
        if (path.startsWith(`/${base}`)) {
          return tab.value;
        }
      } else {
        if (path === `/${tab.value}`) {
          return tab.value;
        }
      }
    }
    return tabList[0].value;
  }, [location.pathname, tabList]);

  return (
    <Tabs
      value={activeTab}
      styles={{
        root: { height: "100%" },
        tabsList: { height: "100%", borderBottom: "none" },
        tab: {
          fontSize: "1rem",
          color: "white",
          borderBottom: "4px solid transparent",
          '&[aria-selected="true"]': {
            color: "white",
            background: "inherit",
            fontWeight: 900,
            borderBottom: "4px solid #228BE6",
          },
          '&[aria-selected="false"]': { background: "inherit" },
        },
        ...styles,
      }}
      onTabChange={(value) => {
        navigate(`/${value}`);
      }}
    >
      <Tabs.List>
        {tabList.map((tab) => (
          <Tabs.Tab key={tab.value} value={tab.value}>
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
    </Tabs>
  );
};
