import {
  INVENTORIES_KEY,
  mutateInventories,
} from "@/api/inventories/useInventoriesQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";

import { customNotification } from "@/utils/notificationShow";
import {
  DefaultApiWmsDiligencePostRequest,
  WmsDiligencePostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useDiligenceHandler = () => {
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: diligenceMutate } = useMutation(
    (params: DefaultApiWmsDiligencePostRequest) =>
      mutateInventories
        .diligence(params)
        .mutationFn(params as DefaultApiWmsDiligencePostRequest | any),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVENTORIES_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.DILIGENCE_SUCCESS,
        });
      },
      onError: (error: any) => {
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.DILIGENCE_FAIL,
        });
      },
    }
  );

  const onSubmit = ({
    wmsDiligencePostRequest,
  }: {
    wmsDiligencePostRequest: WmsDiligencePostRequest;
  }) => {
    diligenceMutate({
      wmsDiligencePostRequest,
    });
  };
  return { onSubmit };
};
