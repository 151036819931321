import { MasterInstance } from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  MasterApiWorkerLogsGetRequest,
  MasterApiWorkerLogsWorkerLogIdUpdateDailyWorkersPutRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const WORKER_KEY = "worker";

export const worker = createQueryKeys(WORKER_KEY, {
  all: null,
  get: (params: MasterApiWorkerLogsGetRequest) => {
    const query = { ...params };
    if (Array.isArray(params.query?.$and) && params.query?.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }

    return {
      queryKey: [query, params],
      queryFn: () => MasterInstance.workerLogsGet(query),
    };
  },
});

export const mutateWorker = createMutationKeys(WORKER_KEY, {
  update: (
    params: MasterApiWorkerLogsWorkerLogIdUpdateDailyWorkersPutRequest
  ) => ({
    mutationKey: [
      params.workerLogId,
      params.workerLogsEnrollDailyWorkersPostRequest,
    ],
    mutationFn: () =>
      MasterInstance.workerLogsWorkerLogIdUpdateDailyWorkersPut(params),
  }),
});

export const equipmentQueryKeys = mergeQueryKeys(worker, mutateWorker);
