import { COMMON_TEXT } from "@/constants/text";
import { useWorkFetch } from "@/fetch/page/work/useWorkFetch";
import { Card, Grid, Text } from "@mantine/core";

const InfoSection = () => {
  const { data: work } = useWorkFetch();
  const workData = work?.data;

  return (
    <Card
      shadow="sm"
      padding="lg"
      radius="md"
      style={{ backgroundColor: "#1E1E1E", color: "white" }}
    >
      <Grid justify="flex-start" align="center">
        <Grid.Col span={3}>
          <Text size={"xl"} fw={"bold"} c={"#9C6ADE"}>
            {workData?.CustName ?? "-"}
          </Text>
          <Text size={"md"} c={"gray"}>
            {COMMON_TEXT.LABEL.CUSTOMER_NAME}
          </Text>
        </Grid.Col>

        <Grid.Col span={3}>
          <Text size={"xl"} fw={"bold"} c={"#51CF66"}>
            {workData?.DVPlaceName ?? "-"}
          </Text>
          <Text size={"md"} c={"gray"}>
            {COMMON_TEXT.LABEL.DV_PLACE_NAME}
          </Text>
        </Grid.Col>
      </Grid>
    </Card>
  );
};

export default InfoSection;
