import { usePutWorkData } from "@/hooks/usePutWorkData";
import { ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useCallback } from "react";

export const useStatus = () => {
  const { pauseMutate, endMutate, startMutate, resumeMutate, cancelEndMutate } =
    usePutWorkData();

  const onStart = useCallback(
    (workId: number) => {
      return startMutate({
        worksWorkIdTrackingStartPostRequest: {
          workId,
        },
        workId,
      });
    },
    [startMutate]
  );

  const onPause = useCallback(
    (workId: number, downtimeReasonCode: string) => {
      pauseMutate({
        worksWorkIdTrackingPausePostRequest: {
          workId,
          downtimeReasonCode,
        },
        workId,
      });
    },
    [pauseMutate]
  );

  const onEnd = useCallback(
    ({
      workId,
      worksWorkIdTrackingStartPostRequest,
    }: ProductionTimeTrackingActionApiWorksWorkIdTrackingEndPostRequest) => {
      endMutate({
        workId,
        worksWorkIdTrackingStartPostRequest,
      });
    },
    [endMutate]
  );

  const onResume = useCallback(
    (workId: number) => {
      resumeMutate({
        workId,
        worksWorkIdTrackingStartPostRequest: {
          workId,
        },
      });
    },
    [resumeMutate]
  );

  const onCancelEnd = useCallback(
    (workId: number) => {
      cancelEndMutate({
        workId,
        worksWorkIdTrackingStartPostRequest: {
          workId,
        },
      });
    },
    [cancelEndMutate]
  );

  return {
    onStart,
    onPause,
    onEnd,
    onResume,
    onCancelEnd,
  };
};
