import { BaseButton } from "@/components/atom/button/BaseButton";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { FormTitle } from "@/components/atom/title/form/FormTitle";
import { MantineCheckedTable } from "@/components/molecule/mantineCheckedTable/MantineCheckedTable";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useEquipmentFormFetch } from "@/fetch/form/useEquipmentFormFetch";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { useAssignWorkerUpdateHandler } from "@/hooks/handler/useAssignWorkerUpdateHandler";
import { WorkerLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useState } from "react";

const AssignUpdateWorker = Object.assign(
  {},
  {
    Template: FormTemplate,
    Title: FormTitle,
    SubTitle: FormSubTitle,
    Button: BaseButton,
    Table: MantineCheckedTable,
  }
);

export const AssignUpdateWorkerForm = (params: {
  formatterProps: WorkerLogsGet200ResponseRowsInner;
}) => {
  const { formatterProps } = params;
  const [codes, setCodes] = useState<string[]>([]);
  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const { equipmentName, equipmentCode } = userData ?? {};

  const columns = [
    { accessorKey: "name", header: "작업자명" },
    { accessorKey: "code", header: "코드" },
  ];

  const today = dayjs(new Date()).format("YYYY-MM-DD");

  const { onSubmit } = useAssignWorkerUpdateHandler();

  const { data: equipmentData } = useEquipmentFormFetch({
    code: equipmentCode,
  });

  const data = equipmentData?.data[0].workerGroupArray;
  const selectedData = formatterProps?.workerGroupArray;

  const handleOnRowSelection = (rows: any[]) => {
    const codes = rows?.map((row: any) => row.code);
    setCodes(codes);
  };

  const handleUpdate = () => {
    onSubmit({
      workerLogId: formatterProps.id as number,
      workerLogsEnrollDailyWorkersPostRequest: {
        dailyWorkerGroup: codes,
      },
    });
  };

  return (
    <AssignUpdateWorker.Template onSubmit={handleUpdate}>
      <AssignUpdateWorker.Title size={"1rem"}>
        {equipmentName} {""}
        {today}
      </AssignUpdateWorker.Title>
      <AssignUpdateWorker.SubTitle>
        {COMMON_TEXT.DESCRIPTION.TODAY_ENROLL_UPDATE_WORKER}
      </AssignUpdateWorker.SubTitle>

      <AssignUpdateWorker.Table
        data={data ?? []}
        columns={columns}
        selectedData={selectedData ?? []}
        onRowSelection={handleOnRowSelection}
      />
    </AssignUpdateWorker.Template>
  );
};
