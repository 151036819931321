import { BaseButton } from "@/components/atom/button/BaseButton";
import { BaseText } from "@/components/atom/text/BaseText";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { Flex, TextInput } from "@mantine/core";
import { IconTrash } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";

const BarcodeInput = Object.assign(
  {},
  {
    Template: FormTemplate,
    Subtitle: FormSubTitle,
    Input: FormTextInput,
    Text: BaseText,
    Button: BaseButton,
  }
);

export const BarcodeInputForm = () => {
  const { closeModal } = useModal();
  const [inputs, setInputs] = useState<string[]>([""]);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setInputs((prevInputs) => [...prevInputs, ""]);
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newInputs = [...inputs];
    newInputs[index] = event.target.value;
    setInputs(newInputs);
  };

  const handleRemoveInput = (index: number) => {
    setInputs((prevInputs) => prevInputs.filter((_, i) => i !== index));
    inputRefs.current = inputRefs.current.filter((_, i) => i !== index);
  };

  useEffect(() => {
    setInputs([""]);
    inputRefs.current = [];
  }, []);

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[inputRefs.current.length - 1]?.focus();
    }
  }, [inputs]);

  const handleReturnBarcodeInputs = () => {
    const values = inputs.filter((input) => input !== "");
    closeModal(values);
  };

  return (
    <BarcodeInput.Template
      onSubmit={handleReturnBarcodeInputs}
      onCancel={() => closeModal({})}
    >
      <Flex direction="column" gap="md" w="100%">
        {inputs.map((input, index) => (
          <Flex w="100%" justify={"flex-end"} gap={"xl"} align={"center"}>
            <BarcodeInput.Text>{index + 1}.</BarcodeInput.Text>
            <TextInput
              w={"100%"}
              size="sm"
              ref={(el) => (inputRefs.current[index] = el)}
              value={input}
              aria-label="Enter Input And Take a Barcode"
              data-autofocus
              onKeyDown={(event) => handleKeyDown(event, index)}
              onChange={(event) => handleChange(event, index)}
            />
            <BarcodeInput.Button
              disabled={!index}
              onClick={() => handleRemoveInput(index)}
              leftIcon={<IconTrash />}
              color="red"
              variant="white"
            >
              {COMMON_TEXT.BUTTON.CANCEL}
            </BarcodeInput.Button>
          </Flex>
        ))}
      </Flex>
    </BarcodeInput.Template>
  );
};
