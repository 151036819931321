import { works } from "@/api/work/useWorkQuery";
import useFetcher from "@/hooks/useFetcher";
import { useWorksSearchState } from "@/hooks/useWorksState.hook";
import { usePaginationStore } from "@/store/pagenation.store";
import { ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum } from "@sizlcorp/sizl-api-document/dist/models";

const { WAITING, PAUSED, WORKING, DONE } =
  ProductionPlansGet200ResponseRowsInnerWorksInnerTrackingStatusEnum;

interface UseWorksFetchProps {
  equipmentCode: string | undefined;
}

export const useWorksFetch = ({ equipmentCode }: UseWorksFetchProps) => {
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));
  const { dates, value, item, spec } = useWorksSearchState();
  const query: any = {
    $and: [
      {
        trackingStatus:
          value.length > 0 ? value : [WAITING, PAUSED, WORKING, DONE],
      },
      ...(item ? [{ itemName: { $eq: item } }] : []),
      ...(spec ? [{ spec: { $eq: spec } }] : []),
    ],
  };

  if (equipmentCode) {
    query.$and.push({ equipmentCode: { $eq: equipmentCode } });
  }

  const { queryKey, queryFn } = works.itemWork({
    page: currentPage ?? 1,
    query: query,
    sort: "-createdAt",
    dates,
    populate: [
      "equipment",
      "summary",
      "item",
      "productionPlan",
      "routingData",
      "groupTargetQuantity",
    ],
    pageSize: 8,
  });

  const { data, Fetcher } = useFetcher({
    queryKey: queryKey,
    queryFn: queryFn,
  });

  return { data, Fetcher };
};
