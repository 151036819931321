import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { useEffect, useState } from "react";

interface MantineCheckedTableProps<TData extends Record<string, any>>
  extends MRT_TableOptions<TData> {
  columns: MRT_ColumnDef<TData>[];
  data: TData[];
  selectedData?: TData[]; // 추가된 prop: 선택할 데이터 리스트
  onRowSelection?: (selectedRows: TData[]) => void;
}

export const MantineCheckedTable = <TData extends Record<string, any>>({
  columns,
  data,
  selectedData,
  onRowSelection,
  ...props
}: MantineCheckedTableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});

  // selectedData 변경 시 rowSelection 초기값 설정
  useEffect(() => {
    const newRowSelection = data.reduce<MRT_RowSelectionState>(
      (acc, item, index) => {
        if (selectedData?.some((selected) => selected.code === item.code)) {
          acc[index] = true;
        }
        return acc;
      },
      {}
    );

    setRowSelection(newRowSelection);
  }, [data, selectedData]);

  useEffect(() => {
    if (onRowSelection) {
      const selectedRows = Object.keys(rowSelection)
        .filter((key) => rowSelection[key])
        .map((key) => data[Number(key)]);

      onRowSelection(selectedRows);
    }
  }, [rowSelection]);

  const table = useMantineReactTable<TData>({
    columns,
    data,
    enableRowSelection: true,
    enableRowNumbers: false,
    enableColumnActions: false,
    enableColumnOrdering: false,
    enableSorting: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    onRowSelectionChange: setRowSelection,
    enableRowActions: false,
    state: { rowSelection },
    positionActionsColumn: "last",
    mantineTableContainerProps: { sx: { maxHeight: "20rem" } },
    ...props,
    defaultColumn: { size: 30 },
  });

  return <MantineReactTable table={table} />;
};
