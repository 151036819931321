import DefaultInstance from "@/instance/axios";
import {
  createMutationKeys,
  createQueryKeys,
  mergeQueryKeys,
} from "@lukemorales/query-key-factory";
import {
  DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest,
  DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest,
  DefaultApiDowntimeReasonsFindPostRequest,
  DefaultApiDowntimeReasonsGetRequest,
  DefaultApiDowntimeReasonsPostRequest,
  DefaultApiDowntimeReasonsUpdateSeqPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";

export const DOWN_TIME_REASONS = "downtimeReasons";

export const downtimeReasons = createQueryKeys(DOWN_TIME_REASONS, {
  all: null,
  get: (params: DefaultApiDowntimeReasonsGetRequest) => {
    // const queryParams = { ...params };
    const queryParams = {
      ...params,
      query: JSON.stringify({ $and: params?.query }),
      sort: params.sort?.length ? params.sort : "seq",
    }; // 비가동사유 조회 시 기본 정렬 표시

    return {
      queryKey: [queryParams, JSON.stringify(queryParams)],
      queryFn: () => DefaultInstance.downtimeReasonsGet(queryParams),
    };
  },
  // get: (params: DefaultApiDowntimeReasonsGetRequest) => {
  //     const query = { ...params }; // params 객체를 복사합니다.

  //     if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
  //         query["query"] = JSON.stringify(params.query);
  //     }
  //     // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
  //     if (params.search) {
  //         query["search"] = params.search;
  //         query["searchFields"] = params.searchFields;
  //     }
  //     return {
  //         queryKey: [query, JSON.stringify(query.query)],
  //         queryFn: () => DefaultInstance.downtimeReasonsGet(query)
  //     }
  // },
  find: (params: DefaultApiDowntimeReasonsFindPostRequest) => {
    const query = { ...params }; // params 객체를 복사합니다.

    if (Array.isArray(params.query.$and) && params.query.$and.length > 0) {
      query["query"] = JSON.stringify(params.query);
    }
    // params.search가 있을 때만 query.search와 query.searchFields를 설정합니다.
    if (params.search) {
      query["search"] = params.search;
      query["searchFields"] = params.searchFields;
    }
    return {
      queryKey: [query, JSON.stringify(query.query)],
      queryFn: () => DefaultInstance.downtimeReasonsFindPost(query),
    };
  },
});

export const mutateDownTimeReasons = createMutationKeys(DOWN_TIME_REASONS, {
  create: (params: DefaultApiDowntimeReasonsPostRequest) => ({
    mutationKey: [params.downtimeReasonsGetRequest],
    mutationFn: () => DefaultInstance.downtimeReasonsPost(params),
  }),
  update: (params: DefaultApiDowntimeReasonsDowntimeReasonCodePutRequest) => ({
    mutationKey: [params.downtimeReasonCode, params.downtimeReasonsGetRequest],
    mutationFn: () =>
      DefaultInstance.downtimeReasonsDowntimeReasonCodePut(params),
  }),
  delete: (
    params: DefaultApiDowntimeReasonsDowntimeReasonCodeDeleteRequest
  ) => ({
    mutationKey: [params.downtimeReasonCode],
    mutationFn: () =>
      DefaultInstance.downtimeReasonsDowntimeReasonCodeDelete(params),
  }),

  updateSeq: (params: DefaultApiDowntimeReasonsUpdateSeqPostRequest) => ({
    mutationKey: [params.equipmentsUpdateSeqPostRequest],
    mutationFn: () => DefaultInstance.downtimeReasonsUpdateSeqPost(params),
  }),
});

export const downtimeReasonsQueryKeys = mergeQueryKeys(
  downtimeReasons,
  mutateDownTimeReasons
);
