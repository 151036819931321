import { mutateOperations } from "@/api/operations/useOperationsQuery";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { LocationAutoComplete } from "@/components/organism/autoComplete/location/location-autoComplate";
import { SitesAutoComplete } from "@/components/organism/autoComplete/site/sites-autoComplete";
import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import { TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { DefaultApiOperationsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const OperationsCreate = Object.assign(
  {},
  {
    Template: FormTemplate,
    TextInput: FormTextInput,
  }
);

interface OperationsCreateFormProps {
  code?: string;
  autoCompleteOnSubmit?: (values: any) => void;
}

export function OperationsCreateForm(params: OperationsCreateFormProps) {
  const { code, autoCompleteOnSubmit } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: postMutate } = useMutation(
    (params: DefaultApiOperationsPostRequest) =>
      mutateOperations.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["operations"]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.OPERATION_CREATE_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        if (error?.response?.data.message === undefined) return;
        customNotification.error({
          message:
            error?.response?.data.message ??
            COMMON_TEXT.NOTIFICATION.OPERATION_CREATE_FAIL,
        });
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: code ?? "",
      name: "",
      siteCode: "",
      fromLocationCode: "",
      toLocationCode: "",
    },
  });

  const onSubmit = () => {
    postMutate({
      operationsGetRequest: {
        code: code ?? form.values.code,
        name: form.values.name,
        siteCode: form.values.siteCode,
        fromLocationCode: form.values.fromLocationCode,
        toLocationCode: form.values.toLocationCode,
      },
    });
  };

  return (
    <OperationsCreate.Template
      onSubmit={
        autoCompleteOnSubmit
          ? () => autoCompleteOnSubmit(form.values)
          : onSubmit
      }
    >
      <TextInput
        withAsterisk
        label={COMMON_TEXT.LABEL.OPERATION_CODE}
        placeholder={COMMON_TEXT.PLACEHOLDER.OPERATION_CODE}
        {...form.getInputProps("code")}
      />
      <TextInput
        withAsterisk
        label={COMMON_TEXT.LABEL.OPERATION_NAME}
        placeholder={COMMON_TEXT.PLACEHOLDER.OPERATION_NAME}
        {...form.getInputProps("name")}
      />
      <SitesAutoComplete
        withAsterisk
        label={COMMON_TEXT.LABEL.SITE_CODE}
        width={"100%"}
        placeholder={COMMON_TEXT.PLACEHOLDER.SITE_CODE}
        {...form.getInputProps("siteCode")}
      />
      <LocationAutoComplete
        withAsterisk
        label={COMMON_TEXT.LABEL.FROM_LOCATION}
        width={"100%"}
        placeholder={COMMON_TEXT.PLACEHOLDER.FROM_LOCATION}
        {...form.getInputProps("fromLocationCode")}
      />
      <LocationAutoComplete
        withAsterisk
        label={COMMON_TEXT.LABEL.TO_LOCATION}
        width={"100%"}
        placeholder={COMMON_TEXT.PLACEHOLDER.FROM_LOCATION}
        {...form.getInputProps("toLocationCode")}
      />
    </OperationsCreate.Template>
  );
}
