import { BasicPagination } from "@/components/atom/pagination/basic/BasicPagination";
import { CalendarTrigger } from "@/components/molecule/calendarTrigger/CalendarTrigger";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import ProductionStatusCard from "@/components/molecule/productionStatusCard/ProductionStatusCard";
import { StatusMultipleChipGroup } from "@/components/molecule/statusMultipleChipGroup/StatusMultipleChipGroup";
import { WorksActionHeader } from "@/components/organism/header/refactor/worksActionHeader/WorksActionHeader";
import PageTemplate from "@/components/template/page/PageTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { trackingStatusInKorean } from "@/constants/trackingType";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useWorksFetch } from "@/fetch/page/useWorksFetch";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import { useWorksSearchActions } from "@/hooks/useWorksActions.hook";
import { useWorksSearchState } from "@/hooks/useWorksState.hook";
import { usePaginationStore } from "@/store/pagenation.store";
import { Flex, Select } from "@mantine/core";
import { WorksItemGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Works = Object.assign(
  {},
  {
    Template: PageTemplate,
    CalendarTrigger: CalendarTrigger,
    StatusMultipleChipGroup: StatusMultipleChipGroup,
    WorksActionHeader: WorksActionHeader,
    Table: MantineDataTable,
    ProductionStatusCard: ProductionStatusCard,
    Select: Select,
    Pagination: BasicPagination,
  }
);

type ConvertedDataType = {
  id: number | undefined;
  scheduleSeq: string | number;
  equipmentName: string;
  itemName: string;
  targetQuantity: string;
  spec: string;
  scheduledAt: string;
  trackingStatus: string | undefined;
  description: string | undefined;
  itemDescription?: string | undefined;
  CustName?: string | undefined;
  DVPlaceName?: string | undefined;
};

export const WorksPage = () => {
  const navigate = useNavigate();
  const { setWorkId } = useLocalStorage();
  const token = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });
  const userData = user?.data;
  const equipmentCode = userData?.equipmentCode;
  const { data, Fetcher: WorksFetch } = useWorksFetch({
    equipmentCode: equipmentCode,
  });
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));

  const { dates, value, item, spec } = useWorksSearchState();
  const { setDates, setValue, setItem, setSpec } = useWorksSearchActions();

  const worksData = data?.data.rows as WorksItemGet200ResponseRowsInner[];
  const totalPages = data?.data.totalPages as number;

  useEffect(() => {
    setPage(1);
  }, [dates, value, item, spec]);

  const onClickMemorize = (data: ConvertedDataType) => {
    setWorkId(data.id);
  };

  const onClickNavigate = (data: ConvertedDataType) => {
    const itemDescription = data.itemDescription; // false 면 비규격
    const customerAndPlace = data.CustName === "-" && data.DVPlaceName === "-";
    const id = data.id;

    if (!itemDescription && customerAndPlace) {
      return navigate(`/work/${id}`);
    }
    if (!itemDescription) {
      return navigate(`/nonStandardWork/${id}`);
    }
    navigate(`/work/${id}`);
  };

  const columns = () => [
    {
      accessorKey: "scheduleSeq",
      header: COMMON_TEXT.COLUMN.SCHEDULE_SEQ,
    },
    {
      accessorKey: "CustName",
      header: COMMON_TEXT.COLUMN.CUSTNAME,
    },
    {
      accessorKey: "DVPlaceName",
      header: COMMON_TEXT.COLUMN.DV_PLACE_NAME,
    },
    {
      accessorKey: "itemName",
      header: `${COMMON_TEXT.COLUMN.ITEM_NAME}(${COMMON_TEXT.COLUMN.ROUTING_CODE})`,
    },
    {
      accessorKey: "targetQuantity",
      header: COMMON_TEXT.COLUMN.TARGET_QUANTITY,
    },
    {
      accessorKey: "groupTargetQuantity",
      header: COMMON_TEXT.COLUMN.GROUP_TARGET_QUANTITY,
    },
    {
      accessorKey: "scheduledAt",
      header: COMMON_TEXT.COLUMN.SCHEDULED_AT,
    },
    {
      accessorKey: "trackingStatus",
      header: COMMON_TEXT.COLUMN.TRACKING_STATUS,
    },
  ];

  const convertedWorksData: ConvertedDataType[] = worksData?.map((workData) => {
    const id = workData?.id;
    const scheduleSeq = workData?.scheduleSeq ?? "-";
    const trackingStatus =
      workData?.trackingStatus !== undefined
        ? trackingStatusInKorean[workData.trackingStatus]
        : "알 수 없음";
    const equipmentName = workData?.equipment?.name ?? "-";
    const itemName = `${workData.item?.name}(${workData.routingData?.code})`;
    const targetQuantity = workData?.targetQuantity
      ? `${workData?.targetQuantity}${workData.item?.unitText}`
      : `-`;
    const spec = workData?.spec ?? "-";
    const scheduledAt = dayjs(workData?.scheduledAt).format("YYYY-MM-DD");
    const description = workData.description;
    const itemDescription = workData.itemDescription;
    const DVPlaceName = workData.DVPlaceName ?? "-";
    const CustName = workData.CustName ?? "-";
    const groupTargetQuantity = workData?.groupTargetQuantity
      ? `${workData?.groupTargetQuantity}${workData.item?.unitText}`
      : `-`;

    return {
      id,
      scheduleSeq,
      equipmentName,
      itemName,
      targetQuantity,
      spec,
      scheduledAt,
      trackingStatus,
      description,
      itemDescription,
      DVPlaceName,
      CustName,
      groupTargetQuantity,
    };
  });

  return (
    <WorksFetch>
      <Works.Template>
        <Flex direction={"column"} gap={"md"}>
          <Works.CalendarTrigger
            size={"1.5rem"}
            dates={dates}
            setDates={setDates}
          />
          <Works.WorksActionHeader
            equipmentCode={equipmentCode}
            value={value}
            spec={spec}
            item={item}
            setValue={setValue}
            setSpec={setSpec}
            setItem={setItem}
          />
          <Works.Table
            onRowClick={(data: ConvertedDataType) => {
              onClickNavigate(data);
              onClickMemorize(data);
            }}
            columns={columns()}
            data={convertedWorksData ?? []}
          />
          <Works.Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => setPage(page)}
          />
        </Flex>
      </Works.Template>
    </WorksFetch>
  );
};
