import { mutateWorker, WORKER_KEY } from "@/api/worker/useWorkerQuery";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";

import { customNotification } from "@/utils/notificationShow";
import {
  MasterApiWorkerLogsWorkerLogIdUpdateDailyWorkersPutRequest,
  WorkerLogsEnrollDailyWorkersPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useAssignWorkerUpdateHandler = () => {
  const { closeModal } = useModal();

  const queryClient = useQueryClient();

  const { mutate: updateMutate } = useMutation(
    (params: MasterApiWorkerLogsWorkerLogIdUpdateDailyWorkersPutRequest) =>
      mutateWorker.update(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([WORKER_KEY]);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.UPDATE_WORKER_SUCCESS,
        });
        closeModal({});
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ??
            COMMON_TEXT.NOTIFICATION.UPDATE_WORKER_FAIL,
        });
      },
    }
  );

  const onSubmit = ({
    workerLogId,
    workerLogsEnrollDailyWorkersPostRequest,
  }: {
    workerLogId: number;
    workerLogsEnrollDailyWorkersPostRequest: WorkerLogsEnrollDailyWorkersPostRequest;
  }) => {
    updateMutate({
      workerLogId,
      workerLogsEnrollDailyWorkersPostRequest,
    });
  };
  return { onSubmit };
};
