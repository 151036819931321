import { BaseButton } from "@/components/atom/button/BaseButton";
import { BasicPagination } from "@/components/atom/pagination/basic/BasicPagination";
import { MantineDataTable } from "@/components/molecule/mantineDataTable/MantineDataTable";
import { AssignWorkerForm } from "@/components/organism/form/AssignWorkerForm";
import { AssignUpdateWorkerForm } from "@/components/organism/form/AssignWorkerUpdateForm";
import PageTemplate from "@/components/template/page/PageTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useWorkerLogFetch } from "@/fetch/page/assignWoker/useWorkerLogFetch";
import { usePaginationStore } from "@/store/pagenation.store";
import { Flex } from "@mantine/core";
import { WorkerLogsGet200ResponseRowsInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";

const AssignWorker = Object.assign(
  {},
  {
    Template: PageTemplate,
    Button: BaseButton,
    Table: MantineDataTable,
    Pagination: BasicPagination,
  }
);

export const AssignWorkerPage = () => {
  const { openModal } = useModal();
  const { data } = useWorkerLogFetch();
  const { currentPage, setPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
    setPage: state.setPage,
  }));

  const totalPages = data?.data.totalPages as number;

  const convertedData = data?.data.rows?.map((row) => {
    return {
      ...row,
      createdAt: dayjs(row?.createdAt).format("YYYY-MM-DD"),
      worker: row?.workerGroupArray?.map((worker) => worker.name).join(", "),
    };
  });

  const openUpdateModal = (
    formatterProps: WorkerLogsGet200ResponseRowsInner
  ) => {
    return openModal(
      <AssignUpdateWorkerForm formatterProps={formatterProps} />,
      "",
      "작업자 수정"
    );
  };

  const column = [
    { accessorKey: "createdAt", header: "작업일" },
    { accessorKey: "worker", header: "작업자" },
    {
      accessoryKey: "actions",
      header: "액션",
      Cell: (formatterProps: any) => {
        const row = formatterProps.row
          .original as WorkerLogsGet200ResponseRowsInner;

        const today = dayjs(row?.createdAt).format("YYYY-MM-DD");
        const isUpdate = today === dayjs(new Date()).format("YYYY-MM-DD");

        return (
          isUpdate && (
            <div>
              <AssignWorker.Button
                variant="outline"
                color="green"
                size="xs"
                onClick={() => openUpdateModal(row)}
              >
                수정
              </AssignWorker.Button>
            </div>
          )
        );
      },
    },
  ];

  const handleTodayAssign = () => {
    return openModal(<AssignWorkerForm />, "", "작업자 저장");
  };

  return (
    <AssignWorker.Template>
      <Flex justify={"flex-end"}>
        <AssignWorker.Button
          color="grape"
          onClick={handleTodayAssign}
          mb={"xl"}
        >
          {COMMON_TEXT.BUTTON.TODAY_ENROLL_WORKER}
        </AssignWorker.Button>
      </Flex>
      <AssignWorker.Table data={convertedData ?? []} columns={column} />;
      <AssignWorker.Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={(page) => setPage(page)}
      />
    </AssignWorker.Template>
  );
};
