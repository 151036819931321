import { mutateEquipment } from "@/api/equipment/useEquipmentQuery";
import { WORKER_KEY } from "@/api/worker/useWorkerQuery";
import { useModal } from "@/context/ModalStackManager";

import { customNotification } from "@/utils/notificationShow";
import {
  MasterApiWorkerLogsEnrollDailyWorkersPostRequest,
  WorkerLogsEnrollDailyWorkersPostRequest,
} from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useEnrollDailyWorkerHandler = () => {
  const { closeModal } = useModal();

  const queryClient = useQueryClient();

  const { mutate: enrollMutate } = useMutation(
    (params: MasterApiWorkerLogsEnrollDailyWorkersPostRequest) =>
      mutateEquipment.enrollDailyWorkers(params).mutationFn(undefined),
    {
      onSuccess: () => {
        customNotification.success({
          message: "작업자 설정이 성공적으로 진행 되었습니다..",
        });
        queryClient.invalidateQueries([WORKER_KEY]);
        closeModal({});
      },
      onError: (error: any) => {
        customNotification.error({
          message:
            error?.response?.data?.message ?? "작업자 설정에 실패했습니다.",
        });
        queryClient.invalidateQueries([WORKER_KEY]);
        closeModal({});
      },
    }
  );

  const onSubmit = ({
    workerLogsEnrollDailyWorkersPostRequest,
  }: {
    workerLogsEnrollDailyWorkersPostRequest: WorkerLogsEnrollDailyWorkersPostRequest;
  }) => {
    enrollMutate({ workerLogsEnrollDailyWorkersPostRequest });
  };
  return { onSubmit };
};
