import { MantineSelectableTable } from "@/components/molecule/mantineSelectableTable/MantineSelectableTable";
import { useLocalStorage } from "@/context/LocalStorageProvider";
import { useNonStandardFetch } from "@/fetch/page/nonStandard/useNonStandardFetch";
import { useStatusHandler } from "@/hooks/handler/useStatusHandler";
import { useEquipmentStore } from "@/store/equipment.store";
import { useNonStandardStore } from "@/store/nonStandard.store";
import { useSocketStore } from "@/store/socket.store";
import { useWorkDataStore } from "@/store/work.store";
import { setDateFormatStringWithTime } from "@/utils/timeUtil";

import { getWorkTrackingStatus } from "@/utils/tranferWorkStatus";
import { Button, NumberInput, Text } from "@mantine/core";
import {
  ProductionPlansGet200ResponseRowsInnerWorksInner,
  WorksItemGet200ResponseRowsInner,
  WorksItemGet200ResponseRowsInnerTrackingStatusEnum,
} from "@sizlcorp/sizl-api-document/dist/models";
import { MRT_ColumnDef } from "mantine-react-table";
import { useEffect, useMemo, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";

export interface ItemType extends Partial<WorksItemGet200ResponseRowsInner> {
  equipmentType: string;
  end: string;
  todoQuantity: number;
  defectCount: number;
}

const NonStandardTable = () => {
  const { setWorkId, workId } = useLocalStorage();
  const navigate = useNavigate();
  const { setSelectedRowIds, setData, data, updateRow, selectedRowIds } =
    useNonStandardStore();
  const { workData } = useWorkDataStore((state) => ({
    workData: state.workData,
  }));

  const { equipment } = useEquipmentStore((state) => ({
    equipment: state.Equipment,
  }));
  const { data: nonStandardData } = useNonStandardFetch({
    workData: workData.work as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });

  const { handleStart, handleEnd, handleResume } = useStatusHandler();
  const { getData } = useSocketStore((state) => ({ getData: state.getData }));
  const counterData = getData(equipment?.code as string);

  // 이전 선택 상태를 저장할 ref
  const prevSelectedRowIdsRef = useRef<number[]>([]);

  const columns = useMemo<MRT_ColumnDef<ItemType>[]>(
    () => [
      {
        accessorKey: "Dong",
        header: "동",
      },
      {
        accessorKey: "itemName",
        header: "제품명",
        Cell: ({ row }) => {
          const { id, itemName, equipment } = row.original;

          const handleNavigate = () => {
            setWorkId(id);
          };

          return equipment?.equipmentType === "PRESS" ? (
            <NavLink to={`/work/${id}`}>
              <Text underline c={"red"} fw={"bold"} onClick={handleNavigate}>
                {itemName}
              </Text>
            </NavLink>
          ) : (
            <div>{itemName}</div>
          );
        },
      },
      {
        accessorKey: "spec",
        header: "규격",
      },
      {
        accessorKey: "PDRev",
        header: "생산치수",
      },
      {
        accessorKey: "Floor",
        header: "층별",
      },
      {
        accessorKey: "ProdQty",
        header: "총수량",
      },
      {
        accessorKey: "Angle",
        header: "각도",
      },
      {
        accessorKey: "targetQuantity",
        header: "목표 생산량",
      },
      {
        accessorKey: "counter",
        header: "카운터",
        Cell: ({ row }) => {
          const { equipment, trackingStatus, finalEquipmentCounter } =
            row.original;

          let count;
          if (trackingStatus === "DONE") {
            count = finalEquipmentCounter ?? 0;
          } else if (trackingStatus === "PAUSED") {
            count = finalEquipmentCounter ?? 0;
          } else if (trackingStatus === "WORKING") {
            count = Number(counterData?.actualProduction ?? 0);
          }

          return <div>{equipment?.equipmentType === "PRESS" && count}</div>;
        },
      },
      {
        accessorKey: "end",
        header: "실적 수량",
        minSize: 100,
        Cell: (value) => {
          const { targetQuantity, id, summary, end } = value.row.original;

          const onChange = (value: number) => {
            updateRow(id as number, { end: value });
          };

          const inputValue = useMemo(() => {
            if (end) return Number(end);
            if (summary?.end === "0") return Number(targetQuantity);
            return Number(summary?.end);
          }, [end, summary?.end, targetQuantity]);

          return (
            <NumberInput
              onClick={(e) => e.stopPropagation()}
              onFocus={(e) => e.stopPropagation()}
              onChange={onChange}
              disabled={value.row.original.trackingStatus === "WAITING"}
              value={inputValue}
            />
          );
        },
      },
      {
        accessorKey: "defectCount",
        header: "불량 수량",
        Cell: (value) => {
          const {
            summary,
            targetQuantity,
            trackingStatus,
            finalEquipmentCounter,
          } = value.row.original;

          const calculateDefectCount = () => {
            let count;
            if (trackingStatus === "DONE" || trackingStatus === "PAUSED") {
              count = finalEquipmentCounter;
            } else if (trackingStatus === "WORKING") {
              count = Number(counterData?.actualProduction ?? 0);
            }
            return Math.max(0, Number(count) - Number(targetQuantity));
          };

          const defectCount = calculateDefectCount();

          return (
            <div>
              {defectCount > 0
                ? defectCount
                : summary?.defectTotal || undefined}
            </div>
          );
        },
      },
      {
        accessorKey: "trackingStatus",
        header: "상태",
        Cell: (value) => {
          const trackingStatus = value.row.original
            .trackingStatus as WorksItemGet200ResponseRowsInnerTrackingStatusEnum;
          return <div>{getWorkTrackingStatus(trackingStatus).status}</div>;
        },
      },
      {
        accessorKey: "endTime",
        header: "작업종료시간",
        Cell: ({ row }) => {
          const { trackingStatus, id, updatedAt } = row.original;
          const handleWorkDone = () => {
            handleEnd({
              workId: id as number,
              worksWorkIdTrackingStartPostRequest: {
                workId: id as number,
                finalEquipmentCounter: counterData?.actualProduction ?? "0",
              },
            });
          };

          const renderEndTimeButton = () => {
            if (trackingStatus === "WORKING") {
              return (
                <Button color="red" variant="outline" onClick={handleWorkDone}>
                  {"작업종료"}
                </Button>
              );
            } else if (trackingStatus === "DONE") {
              return setDateFormatStringWithTime(updatedAt);
            } else {
              return (
                <Button
                  color="red"
                  disabled
                  variant="outline"
                  onClick={handleWorkDone}
                >
                  {"작업종료"}
                </Button>
              );
            }
          };

          return <div>{renderEndTimeButton()}</div>;
        },
      },
    ],
    [counterData]
  );

  useEffect(() => {
    if (nonStandardData?.data.rows) {
      if (nonStandardData?.data.rows.length === 1) {
        navigate(`/work/${workId}`);
      }

      setData(nonStandardData?.data.rows as ItemType[]);
    }
  }, [nonStandardData]);

  useEffect(() => {
    const newSelectedRowIds = selectedRowIds?.filter(
      (id) => !prevSelectedRowIdsRef.current.includes(id)
    );

    newSelectedRowIds?.forEach((id) => {
      const selectedRow = data.find((row) => row.id === id);
      if (selectedRow) {
        const IsWorkOrderInProgress =
          selectedRow.equipment?.activeWorkId !== selectedRow.id;

        if (selectedRow.trackingStatus === "WAITING") {
          handleStart({
            workId: selectedRow.id as number,
            IsWorkOrderInProgress: IsWorkOrderInProgress,
          });
        } else if (selectedRow.trackingStatus === "PAUSED") {
          handleResume({
            workId: selectedRow.id as number,
            IsWorkOrderInProgress: IsWorkOrderInProgress,
          });
        }
      }
    });

    // 현재 선택 상태를 이전 상태로 업데이트
    prevSelectedRowIdsRef.current = selectedRowIds ?? [];
  }, [selectedRowIds]);

  useEffect(() => {
    if (counterData) {
      data.forEach((row) => {
        // PRESS 장비에 대해서만 defectCount를 업데이트하는 경우
        if (row.equipment?.equipmentType === "PRESS") {
          const newDefectCount =
            Number(counterData.actualProduction ?? 0) -
            Number(row.targetQuantity ?? 0);

          if (newDefectCount > 0) {
            updateRow(row.id as number, { defectCount: newDefectCount });
          }
        }
      });
    }
  }, [counterData]);

  useEffect(() => {
    return () => {
      setSelectedRowIds([]);
    };
  }, []);

  return (
    <MantineSelectableTable
      columns={columns}
      data={data ?? []}
      enableSorting
      enableRowSelection={(row) => {
        return !(
          row.original.trackingStatus === "DONE" &&
          Number(row?.original?.targetQuantity) <=
            Number(row.original.summary?.end)
        );
      }}
      enableMultiRowSelection={true}
      onRowSelection={(rows) => {
        setSelectedRowIds(rows?.map((row) => row.id) as number[]);
      }}
      mantineTableHeadCellProps={() => {
        return {
          sx: {
            backgroundColor: "#161B21",
            "& .mantine-Checkbox-input": {
              display: "none",
            },
          },
        };
      }}
      mantineTableBodyCellProps={(data) => {
        const { trackingStatus } = data.row.original ?? {};
        const isDone = trackingStatus === "DONE";
        return {
          style: {
            backgroundColor: isDone ? "#068a23" : "#161B21",
            color: "#FFFFFF",
          },
        };
      }}
    />
  );
};

export default NonStandardTable;
