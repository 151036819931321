import { worker } from "@/api/worker/useWorkerQuery";
import { useUserFetch } from "@/fetch/user/useUserFetch";
import useFetcher from "@/hooks/useFetcher";
import { usePaginationStore } from "@/store/pagenation.store";

interface QueryCondition {
  [key: string]: any;
}

interface Query {
  $and?: QueryCondition[];
  [key: string]: any;
}

interface WorkerLogFetchOptions {
  query?: Query;
}

export const useWorkerLogFetch = (options?: WorkerLogFetchOptions) => {
  const { currentPage } = usePaginationStore((state) => ({
    currentPage: state.currentPage,
  }));

  const token: string | null = localStorage.getItem("authToken");
  const { data: user } = useUserFetch({ token: token ?? "", enabled: !!token });

  const userData = user?.data;
  const equipmentCode: string | undefined = userData?.equipmentCode;

  // 기본 쿼리 설정: equipmentCode가 있으면 필터 추가
  const defaultQuery: Query = { $and: [] };

  if (equipmentCode) {
    defaultQuery.$and!.push({ equipmentCode: { $eq: equipmentCode } });
  }

  // 옵션에서 custom query 추출
  const customQuery = options?.query;

  // 기본 쿼리와 custom query 병합
  const finalQuery: Query = { ...defaultQuery };
  if (customQuery) {
    Object.keys(customQuery).forEach((key) => {
      if (key === "$and" && Array.isArray(customQuery.$and)) {
        finalQuery.$and = finalQuery.$and
          ? finalQuery.$and.concat(customQuery.$and)
          : customQuery.$and;
      } else {
        finalQuery[key] = customQuery[key];
      }
    });
  }

  const { queryKey, queryFn } = worker.get({
    query: finalQuery,
    populate: ["workerGroupArray"],
    page: currentPage ?? 1,
  });

  const { data, Fetcher } = useFetcher(
    {
      queryKey,
      queryFn,
    },
    {
      enabled: !!token,
    }
  );

  return { data, Fetcher };
};
