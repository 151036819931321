import {
  DOWN_TIME_REASONS,
  mutateDownTimeReasons,
} from "@/api/downtimeReasons/useDowntimeReasonsQuery";
import { FormTextInput } from "@/components/atom/textInput/form/FormTextInput";
import { FormSubTitle } from "@/components/atom/title/form/FormSubTitle";
import { FormTitle } from "@/components/atom/title/form/FormTitle";
import { OperationAutoComplete } from "@/components/organism/autoComplete/operation/operation-autoComplete";

import FormTemplate from "@/components/template/form/FormTemplate";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { customNotification } from "@/utils/notificationShow";
import { useForm } from "@mantine/form";
import { DefaultApiDowntimeReasonsPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

const DownTimeReasonsCreate = Object.assign(
  {},
  {
    Template: FormTemplate,
    Title: FormTitle,
    SubTitle: FormSubTitle,
    TextInput: FormTextInput,
    OperationAutoComplete: OperationAutoComplete,
  }
);

export const DownTimeReasonsCreateForm = (params: {
  name: string;
  onSubmit?: (values: any) => void;
}) => {
  const { name } = params;
  const { closeModal } = useModal();
  const queryClient = useQueryClient();

  const { mutate: createMutate } = useMutation(
    (params: DefaultApiDowntimeReasonsPostRequest) =>
      mutateDownTimeReasons.create(params).mutationFn(undefined),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([DOWN_TIME_REASONS]);
        closeModal(form.values);
        customNotification.success({
          message: COMMON_TEXT.NOTIFICATION.DOWNTIME_REASON_CREATE_SUCCESS,
        });
      },
      onError: (error: AxiosError<Error>) => {
        if (error.response?.data.message === undefined) return;
        customNotification.error({
          message:
            error.response.data.message ??
            COMMON_TEXT.NOTIFICATION.DOWNTIME_REASON_CREATE_SUCCESS,
        });
      },
    }
  );

  const form = useForm({
    initialValues: {
      code: undefined,
      name: name,
      operationCode: undefined,
    },
  });

  const onSubmit = async () => {
    createMutate({
      downtimeReasonsGetRequest: {
        code: form.values.code!,
        name: form.values.name!,
        operationCode: form.values.operationCode!,
      },
    });
  };

  return (
    <DownTimeReasonsCreate.Template onSubmit={onSubmit}>
      <DownTimeReasonsCreate.TextInput
        withAsterisk
        label={COMMON_TEXT.LABEL.DOWNTIME_REASON_CODE}
        {...form.getInputProps("code")}
      />
      <DownTimeReasonsCreate.TextInput
        withAsterisk
        label={COMMON_TEXT.LABEL.DOWNTIME_REASON_NAME}
        {...form.getInputProps("name")}
      />
      <DownTimeReasonsCreate.OperationAutoComplete
        label={COMMON_TEXT.LABEL.OPERATION_CODE}
        {...form.getInputProps("operationCode")}
      />
    </DownTimeReasonsCreate.Template>
  );
};
