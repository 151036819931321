import { HeaderActionButton } from "@/components/atom/button/headerAction/HeaderActionButton";
import { StatusButtonGroup } from "@/components/molecule/statusButtonGroup/StatusButtonGroup";
import { InputLogsForm } from "@/components/organism/form/InputLogsForm";
import { NonStandardCompleteForm } from "@/components/organism/form/NonStandardCompleteForm";
import { WorkLogsForm } from "@/components/organism/form/WorkLogsForm";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { useNonStandardFetch } from "@/fetch/page/nonStandard/useNonStandardFetch";
import { useWorkFetch } from "@/fetch/page/work/useWorkFetch";
import { useMaterialManagementHandler } from "@/hooks/handler/useMaterialManagementHandler";
import { useNonStandardStore } from "@/store/nonStandard.store";
import { Flex } from "@mantine/core";
import { ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";

export interface ButtonConfig {
  condition?: () => boolean;
  buttons: {
    key: string;
    color: string;
    icon?: JSX.Element;
    onClick: () => void;
    text: string;
    disabled?: boolean;
  }[];
}

const NonStandard = Object.assign(
  {},
  {
    ActionButton: HeaderActionButton,
    StatusButtonGroup: StatusButtonGroup,
  }
);

export const NonStandardHeader = () => {
  const { selectedRowIds } = useNonStandardStore();
  const { data: work } = useWorkFetch();
  const workData = work?.data;
  const { data: nonStandardData } = useNonStandardFetch({
    workData: workData as ProductionPlansGet200ResponseRowsInnerWorksInner,
  });
  const { openModal } = useModal();
  const { handleInputRawMaterial, handleInputRawMaterialAll } =
    useMaterialManagementHandler();

  const worksIds = nonStandardData?.data.rows?.map((row) => {
    return row.id;
  });

  const LeftButtons: ButtonConfig[] = [
    {
      buttons: [
        {
          key: "input",
          color: "teal.6",
          onClick: handleInputRawMaterial,
          text: COMMON_TEXT.BUTTON.RAW_MATERIAL_INPUT,
        },
        {
          key: "inputAll",
          color: "teal.6",
          onClick: handleInputRawMaterialAll,
          text: COMMON_TEXT.BUTTON.RAW_MATERIAL_ALL_INPUT,
        },
        {
          key: "log",
          color: "yellow.6",
          onClick: () =>
            openModal(
              <InputLogsForm workIds={worksIds as number[]} />,
              "",
              COMMON_TEXT.FORM.INPUT_LOGS_TITLE
            ),
          text: COMMON_TEXT.BUTTON.INPUT_LOGS,
        },
        {
          key: "workLogs",
          color: "cyan.6",
          onClick: () =>
            openModal(
              <WorkLogsForm workIds={worksIds as number[]} />,
              "",
              COMMON_TEXT.FORM.WORK_LOGS_TITLE
            ),
          text: COMMON_TEXT.BUTTON.WORK_LOGS,
        },
      ],
    },
  ];

  const RightButtons: ButtonConfig[] = [
    {
      buttons: [
        {
          key: "workDone",
          color: "blue.6",
          onClick: () =>
            openModal(
              <NonStandardCompleteForm />,
              "",
              COMMON_TEXT.FORM.NON_STANDARD_PRODUCTION_INPUT
            ),
          text: COMMON_TEXT.BUTTON.WORK_DONE,
          disabled: selectedRowIds?.length === 0,
        },
      ],
    },
  ];

  return (
    <Flex gap={"xs"} justify={"space-between"} mb={"md"}>
      <Flex gap={"xs"}>
        {LeftButtons.map((config) =>
          config.buttons.map((button) => (
            <NonStandard.ActionButton
              key={button.key}
              onClick={button.onClick}
              color={button.color}
              leftIcon={button.icon}
            >
              {button.text}
            </NonStandard.ActionButton>
          ))
        )}
      </Flex>
      {RightButtons.map((config) =>
        config.buttons.map((button) => (
          <NonStandard.ActionButton
            disabled={button.disabled}
            key={button.key}
            onClick={button.onClick}
            color={button.color}
            leftIcon={button.icon}
          >
            {button.text}
          </NonStandard.ActionButton>
        ))
      )}
    </Flex>
  );
};
