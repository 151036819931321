import { Button, Flex, Select, SelectProps, Text } from "@mantine/core";
import { EventHandler, ReactNode, forwardRef, useState } from "react";

import styled from "@emotion/styled";

import { mutateSites } from "@/api/sites/useSitesQuery";
import {
  SitesOption,
  useSiteCode,
  useSitesAutoComplete,
} from "@/components/organism/autoComplete/site/auto-useGetSitesQuery";
import { SiteCreateForm } from "@/components/organism/form/SiteCreateForm";
import { COMMON_TEXT } from "@/constants/text";
import { useModal } from "@/context/ModalStackManager";
import { MasterApiSitesPostRequest } from "@sizlcorp/sizl-api-document/dist/models";
import { IconX } from "@tabler/icons-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
interface SitesProps extends React.ComponentPropsWithoutRef<"div"> {
  // group: string;
  label: string;
  value: string;
}

export interface SitesAutoCompleteProps
  extends Partial<SelectProps>,
    Partial<React.RefAttributes<HTMLInputElement>> {
  width?: string;
  value?: string | null;
  onChange?: (SitesCode: string | null) => void;
  setSiteName?: (name: string) => void;
  maxDropdownHeight?: number;
}

// 입력값: value (Sites 모델의 code)
// 출력값: onChange (Sites 모델의 code)

export const SitesAutoComplete = (params: SitesAutoCompleteProps) => {
  const {
    width,
    value: SitesCode,
    onChange,
    maxDropdownHeight,
    setSiteName,
    ...etcParams
  } = params;
  const [focused, setFocused] = useState<boolean>(true);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const { data: options } = useSitesAutoComplete(focused, searchKeyword);

  const { data: initialOptions } = useSiteCode(!!SitesCode, SitesCode ?? null);

  let selectedSites = initialOptions?.find(
    (Sites) => Sites.value === SitesCode
  );
  const onChangeHandler = (e: string | null) => {
    selectedSites = options?.find((Sites) => Sites.value === e);
    setSiteName && setSiteName(selectedSites?.label ?? "");
    onChange && onChange(e);
  };

  const SelectSites = forwardRef<HTMLDivElement, SitesProps>(
    ({ label: name, value: code, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Flex direction="row" justify="space-between" align="center">
          <Flex align={"center"}>
            <Text>{name}</Text>
            <Text fz="xs">(품번: {code})</Text>
          </Flex>
          {/* <Text fz="xs">{group}</Text> */}
        </Flex>
      </div>
    )
  );

  return (
    <SiteSelect
      withinPortal
      onDropdownOpen={() => setFocused(true)}
      onDropdownClose={() => setFocused(false)}
      clearable
      inputContainer={(children: ReactNode) => (
        <div style={{ position: "relative" }}>
          {children}
          {selectedSites?.value && (
            <div
              style={{
                position: "absolute",
                bottom: "-2px",
                fontSize: "0.8em",
                color: "#888",
                left: "10px",
              }}
            >
              {"Code: " + selectedSites?.value}
            </div>
          )}
        </div>
      )}
      value={SitesCode}
      itemComponent={SelectSites}
      searchValue={searchKeyword}
      data={[...(options ?? []), ...(initialOptions ?? [])].reduce(
        (unique: SitesProps[], option: SitesProps) => {
          return unique.some((u) => u.value === option.value)
            ? unique
            : [...unique, option];
        },
        []
      )}
      searchable
      maxDropdownHeight={maxDropdownHeight ?? 150}
      onChange={onChangeHandler}
      onSearchChange={setSearchKeyword}
      rightSection={SitesInfo({
        Sites: selectedSites as SitesOption,
        onChange: onChangeHandler,
      })}
      filter={(value, item) =>
        item?.label?.toLowerCase().includes(value.toLowerCase().trim()) ||
        item?.value?.toLowerCase().includes(value.toLowerCase().trim())
      }
      nothingFound={AddNewSites({
        SitesName: searchKeyword,
        onChange: onChangeHandler,
      })}
      {...etcParams}
    />
  );
};

const SitesInfo = (params: {
  Sites?: SitesOption;
  onChange: (SitesCode: string | null) => void;
}) => {
  const { Sites, onChange } = params;

  const clearHandler: React.MouseEventHandler<SVGSVGElement> = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onChange(null);
  };

  return Sites?.value ? (
    <SitesInfoLabel>
      {/* ({Sites.value}) */}
      <IconX size="1rem" onClick={clearHandler} />
    </SitesInfoLabel>
  ) : null;
};

const AddNewSites = (params: {
  SitesName: string;
  onChange: (SitesCode: string) => void;
}) => {
  const { SitesName, onChange } = params;
  const { openModal, closeModal } = useModal();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { mutate } = useMutation(
    (params: MasterApiSitesPostRequest) =>
      mutateSites
        .create(params)
        .mutationFn(params as MasterApiSitesPostRequest | any),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["sites"]);

        closeModal(res.data);
      },
      onError: (error) => {},
    }
  );

  const onCloseHandler: EventHandler<any> = (values) => {
    mutate({
      sitesGetRequest: {
        code: values.code!,
        name: values.name!,
      },
    });
  };
  const addNewModalHandler: React.MouseEventHandler<HTMLButtonElement> = (
    e
  ) => {
    e.preventDefault();
    openModal(
      <SiteCreateForm name={SitesName} autoCompleteOnSubmit={onCloseHandler} />,
      null,
      COMMON_TEXT.FORM.SITE_CREATE_TITLE,
      true
    ).then((result) => {
      onChange(result?.code);
    });
  };

  return (
    <Button variant="subtle" onClick={addNewModalHandler}>
      {COMMON_TEXT.FORM.SITE_CREATE_TITLE} ({SitesName})
    </Button>
  );
};
const SitesInfoLabel = styled.div`
  font-size: 12px;
  color: #666666;
  // padding-right: 8px;
  padding-left: 0.4rem;
  display: flex;
  align-items: center;
  position: absolute;
`;

const SiteSelect = styled(Select)<{ value: string | undefined | null }>`
  .mantine-Select-input {
    font-size: 0.8rem;
    padding-bottom: ${(props) => (props.value ? "0.8rem !important" : "")};

    &.item-dirty {
      color: cadetblue !important;
      border-color: cadetblue;
    }
  }
  .mantine-Select-error {
    font-size: 11px;
    color: red;
  }
`;
